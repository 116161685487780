.pop-up__wrapper
    position: fixed
    padding: 0 20px
    display: none
    flex-direction: column
    align-items: center
    max-width: 320px
    width: 100%
    border-radius: 34px
    background-color: white
    z-index: 4
    left: 50%
    top: 50%
    transform: translate(-50%, 30%)

    @media screen and (max-width: 376px)
        transform: translate(-50%, 20%)

    @media screen and (min-width: 992px)
        left: 70px
        top: unset
        bottom: 70px
        transform: none
        max-width: 340px

    &.active
        display: flex

    .pop-up__text
        margin: 35px 0
        font-size: 16px
        color: #373a3b
        text-align: center

        a
            color: #373a3b

    .pop-up__buttons
        display: flex
        flex-direction: column
        align-items: center

        .btn
            width: 250px
            border-radius: 34px
            padding: 17px 30px
            cursor: pointer
            outline: none
            font-size: 18px
            color: white
            border: 0

            @media screen and (min-width: 992px)
                width: 300px
                padding: 23px 30px

            &.reject__btn
                width: 240px
                border: none
                background-color: white
                color: #7b868c
