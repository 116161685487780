.bottom-section
  position: relative

  @media screen and (min-width: 1400px)
    overflow: hidden

  &:after
    content: ''
    border-left: 160vw solid transparent
    border-right: 160vw solid transparent
    border-bottom: 1100px solid rgba(255, 255, 255, 0.1)
    position: absolute
    left: -111%
    bottom: -50px
    z-index: -1

    @media screen and (min-width: 768px)
      border-bottom: 1150px solid rgba(255, 255, 255, 0.1)

    @media screen and (min-width: 992px)
      border-bottom: 1600px solid rgba(255, 255, 255, 0.1)
      //top: 0
      //bottom: unset

    @media screen and (min-width: 1400px)
      border-bottom: 1800px solid rgba(255, 255, 255, 0.1)
      border-left: 90vw solid transparent
      border-right: 90vw solid transparent
      left: -40%
      bottom: -40%

    @media screen and (min-width: 1700px)
      border-bottom: 1900px solid rgba(255, 255, 255, 0.1)
      border-left: 70vw solid transparent
      border-right: 70vw solid transparent
      left: -20.5%

    @media screen and (min-width: 1900px)
      left: -20%

  button.not-done
    font-weight: bold
    height: 41px
    padding: 10px 20px
    font-size: 14px
    background-color: transparent
    color: white
    border: 2px solid white
    outline: none
    border-radius: 34px
    margin: 0 auto 200px auto
    transition: all 0.3s linear
    display: block

    @media screen and (min-width: 992px)
      margin: 200px auto 0 auto
      padding: 10px 30px
      height: 68px
      font-size: 18px

    @media screen and (min-width: 1400px)
      margin: 400px auto 0 auto

    @media screen and (min-width: 1700px)
      margin: 500px auto 0 auto

    &:hover
      cursor: pointer
      border: 2px solid rgba(255, 255, 255, 0.1)

    img
      transform: rotate(180deg)
      margin-left: 5px
      width: 15px

  .total-price
    margin-top: 70px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

    @media screen and (min-width: 992px)
      margin-top: 150px
      flex-direction: row

    h2.description
      font-family: "museo-slab", serif
      color: white
      font-size: 21px
      line-height: 25px
      text-align: center

      @media screen and (min-width: 992px)
        font-size: 60px
        text-align: right
        line-height: 65px

    .price
      color: #7b868c
      position: relative

      @media screen and (min-width: 992px)
        margin-left: 50px

      svg
        width: 205px
        height: 175px

        @media screen and (min-width: 992px)
          width: auto
          height: auto

        g#Define-your-Project-Main
          fill: white
          stroke: white

      .text
        position: absolute
        width: 100%
        height: 100%
        text-align: center
        display: flex
        flex-direction: column
        padding: 17px 0

        @media screen and (min-width: 992px)
          padding: 34px 0

        h2.title
          margin: 0
          font-size: 12px
          font-weight: normal

          @media screen and (min-width: 992px)
            font-size: 22px
            line-height: 1.14

        .amount
          flex-grow: 1
          display: flex
          flex-direction: column
          justify-content: center

          h2
            margin: 0

            span
              line-height: 1
              font-size: 36px
              position: relative
              color: #323e48
              display: inline-block

              @media screen and (min-width: 992px)
                font-size: 60px
                line-height: 1.17

              &::after
                content: '*'
                position: absolute
                top: 2px
                right: -15px
                font-size: 20px

                @media screen and (min-width: 992px)
                  right: -20px
                  font-size: 30px
                  top: 7px

          span
            font-size: 12px

            @media screen and (min-width: 992px)
              font-size: 22px
              line-height: 1.14

        .tip
          font-size: 10px

          @media screen and (min-width: 992px)
            font-size: 12px
            line-height: 1.33

  p.info
    text-align: center

    &.mobile
      margin: 35px 0
      color: white
      font-size: 15px

      @media screen and (min-width: 768px)
        display: none

    &.desktop
      margin: 65px 0 0
      color: #a2a9ad
      display: none
      font-size: 18px

      span
        color: white

      @media screen and (min-width: 768px)
        display: block

  h4.before-form
    font-weight: 500
    color: #e6e7ed
    font-size: 15px
    text-align: center
    margin-top: 0
    margin: 35px 0

    @media screen and (min-width: 992px)
      margin: 110px 0 170px
      font-size: 28px


  .form-wrapper
    width: 85%
    margin: 0 auto 50px auto

    @media screen and (min-width: 1400px)
      width: 60%
