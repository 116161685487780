.review
  margin-top: 40px
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap

  @media screen and (min-width: 1530px)
    justify-content: flex-start

  h2.number
    margin: 0
    font-size: 32px
    font-weight: bold
    color: #93d500

    @media screen and (min-width: 992px)
      font-size: 80px

  span
    font-size: 12px
    color: #323e48
    margin: 0 40px 0 20px
    max-width: 90px

  @media screen and (min-width: 992px)
    font-size: 17.5px

  .stars
    img
      width: 28px
      height: 28px

      @media screen and (min-width: 992px)
        width: auto
        height: auto