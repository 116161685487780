footer.footer
  height: 70px
  width: 100%
  
  &.dark 
    .footer__content 
      span
        color: #323e48
      a
        color: #323e48

  .footer__content
    margin: 0 auto
    width: 90%

    @media screen and (min-width: 768px)
      position: relative
      width: 80%

    span
      color: white
      font-size: 12px

      &:nth-of-type(1n+2):not(:last-of-type)
        padding: 0 20px
        border-right: 1px solid white

        @media screen and (max-width: 540px)
          padding: 0 5px

      &:last-of-type
        padding-left: 20px

        @media screen and (max-width: 540px)
          padding-left: 5px

      a
        color: white
        text-decoration: none

      @media screen and (max-width: 540px)
        &:first-of-type
          display: block

        &:nth-of-type(2)
          padding-left: 0 !important
