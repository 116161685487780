.article-section {
  width: 100%;
  margin-top: 80px;

  @media screen and (min-width: 1280px) {
    margin-top: 95px;
  }

  @media screen and (min-width: 1920px) {
    margin-top: 200px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .section__description {
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;
      color: #323e48;

      @media screen and (min-width: 1280px) {
        width: 80%;
      }

      h1,
      h1 * {
        font-size: 36px;
        line-height: 130%;
        font-weight: bold;
        font-family: "museo-slab", serif;
        margin: 0;
        color: #323e48;

        @media screen and (min-width: 768px) {
          font-size: 40px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 64px;
          line-height: 120%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 90px;
          line-height: 1.08;
          margin: 40px 0;
        }

        strong {
          font-family: inherit;
        }
      }

      h2, h2 * {
        font-size: 32px;
        line-height: 40px;
        color: #323e48;
        font-family: "museo-slab", serif;

        @media screen and (min-width: 768px) {
          font-size: 36px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 48px;
          line-height: 120%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 65px;
          line-height: 70px;
        }
      }

      h3, h3 * {
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        margin-top: 16px;
        color: #323e48;

        @media screen and (min-width: 1280px) {
          font-size: 32px;
          line-height: 40px;
        }

        @media screen and (min-width: 1920px) {
          font-size: 36px;
        }
      }

      h4, h4 * {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        margin-top: 16px;
        color: #323e48;

        @media screen and (min-width: 1280px) {
          font-size: 28px;
          line-height: 120%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 30px;
        }
      }

      h5, h5 * {
        font-weight: 600;
        font-size: 22px;
        line-height: 130%;
        margin-top: 16px;
        color: #323e48;

        @media screen and (min-width: 1280px) {
          font-size: 26px;
          line-height: 120%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 28px;
        }
      }

      h6, h6 * {
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        margin-top: 16px;
        color: #323e48;

        @media screen and (min-width: 1280px) {
          font-size: 22px;
          line-height: 120%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 24px;
        }
      }

      p {
        font-size: 18px;
        line-height: 25px;
        color: #323e48;

        @media screen and (min-width: 768px) {
          line-height: 28px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 20px;
          line-height: 140%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 22px;
          line-height: 40px;
        }
      }

      a {
        color: #323e48;
      }

      blockquote {
        margin: 10px 0;
        padding: 0;
        padding-left: 20px;
        border-left: 4px solid #323e48;

        font-size: 18px;
        line-height: 25px;
        color: #323e48;

        @media screen and (min-width: 768px) {
          line-height: 28px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 20px;
          line-height: 140%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 22px;
          line-height: 40px;
        }
      }

      ul li, ol li {
        font-size: 18px;
        line-height: 150%;
        color: #323e48;

        @media screen and (min-width: 1280px) {
          font-size: 20px;
          line-height: 140%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 22px;
          line-height: 40px;
        }
      }
    }

    .section__image {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      section.media-section {
        margin-top: 0;
        .content {
          width: 100%;
        }
      }

      @media screen and (min-width: 768px) {
        margin-top: 50px;
      }

      @media screen and (min-width: 1920px) {
        margin-top: 180px;
      }

      .left_image {
        width: 50%;
      }

      .right_image {
        width: 40%;
        padding-top: 80px;

        @media screen and (min-width: 768px) {
          padding-top: 150px;
        }

        @media screen and (min-width: 1280px) {
          padding-top: 200px;
        }

        @media screen and (min-width: 1920px) {
          padding-top: 430px;
        }
      }

      img {
        width: 100%;
        height: auto;
        filter: drop-shadow(45px 45px 40px rgba(50, 62, 72, 0.22));
        //-webkit-appearance: none;
        //-webkit-box-shadow: 45px 45px 40px rgba(50, 62, 72, 0.22);
        //box-shadow: 45px 45px 40px rgba(50, 62, 72, 0.22);

        @media screen and (min-width: 1280px) {
         filter: drop-shadow(85px 85px 60px rgba(50, 62, 72, 0.22));
          //-webkit-appearance: none;
          //-webkit-box-shadow: 85px 85px 60px rgba(50, 62, 72, 0.22);
          //box-shadow: 85px 85px 60px rgba(50, 62, 72, 0.22);
        }
      }
    }
  }
}
