section.anchors
  position: fixed
  z-index: 3
  top: 50%
  right: 50px

  display: flex
  flex-direction: column
  align-items: flex-end
  width: 0

  @media screen and (min-width: 992px)
    right: 100px

  .react-reveal
    text-align: right
    display: inline-block

  &.black

    .label.active
      background-color: #323e48
      color: white

      svg
        g#Grey-Annotations
          stroke: white
          fill: white


    svg
      g#Grey-Annotations
        stroke: #323e48

  a
    color: black
    text-decoration: none

    &:not(.hovered)
      display: inline-block

      .label:not(.active)
        width: 20px
        padding: 20px 0
        margin-left: auto
        margin-right: 10px

        span
          width: 0

    &.hovered
      .label
        width: 250px
        background: white
        border-radius: 20px
        padding: 10px

        span
          visibility: visible

  .label
    margin: 5px 0
    width: 250px
    display: flex
    align-items: center
    justify-content: space-between
    padding: 10px
    cursor: pointer
    text-align: center
    max-height: 40px

    span
      width: 100%
      visibility: hidden
      white-space: nowrap

    &.active
      width: 100%
      background: white
      border-radius: 20px
      max-width: 250px

      span
        width: 250px
        visibility: visible

    &:hover
      svg
        g#Grey-Annotations
          stroke: #323e48
