.article-categories {
  margin-top: 40px;
  width: 100%;
  z-index: 10;
  background: #ffffff;
  box-shadow: 80px 80px 60px rgba(123, 134, 140, 0.135899);

  @media screen and (min-width: 1280px) {
    margin-top: 88px;
  }
  @media screen and (min-width: 1920px) {
    margin-top: 110px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
  }
}
