section.solution-preview {
  width: 100%;
  margin-top: 25px;

  @media screen and (min-width: 1280px) {
    margin-top: 60px;
  }

  @media screen and (min-width: 1920px) {
    margin-top: 125px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;

    @media screen and (min-width: 1280px) {
      flex-direction: row;
    }

    &__description {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 1280px) {
        width: 49%;
      }

      @media screen and (min-width: 1920px) {
        width: 48%;
      }

      .company {
        display: flex;
        align-items: center;

        &__logo {
          img {
            width: 40px;
            height: auto;
            filter: drop-shadow(25px 25px 20px rgba(50, 62, 72, 0.3));

            @media screen and (min-width: 1280px) {
              width: 60px;
            }
            @media screen and (min-width: 1920px) {
              width: 80px;
            }
          }
        }
        &__description {
          font-size: 18px;
          line-height: 20px;
          margin-left: 20px;
          color: #323e48;

          @media screen and (min-width: 768px) {
            font-size: 18px;
            line-height: 28px;
          }

          @media screen and (min-width: 1280px) {
            margin-left: 40px;
            font-size: 20px;
            line-height: 140%;
          }

          @media screen and (min-width: 1920px) {
            font-size: 22px;
            line-height: 40px;
          }
        }
      }

      .solution {
        &__types {
          margin-top: 0;
          display: flex;
          padding: 0;

          @media screen and (min-width: 1280px) {
            margin-top: 30px;
          }

          @media screen and (min-width: 1920px) {
            margin-top: 50px;
          }
          li {
            margin: 0 10px;
            font-weight: 500;
            font-size: 11px;
            line-height: 18px;
            color: #7b868c;
            list-style-type: none;
            position: relative;

            @media screen and (min-width: 768px) {
              font-size: 14px;
              line-height: 18px;
            }

            @media screen and (min-width: 1280px) {
              font-size: 20px;
              line-height: 140%;
              margin: 0 20px;
            }

            @media screen and (min-width: 1920px) {
              font-size: 25px;
              line-height: 40px;
            }

            &::after {
              content: "";
              position: absolute;
              top: 20%;
              right: -10px;
              width: 1px;
              height: 60%;
              background-color: #7b868c;

              @media screen and (min-width: 768px) {
                width: 2px;
              }

              @media screen and (min-width: 1280px) {
                right: -20px;
                top: 15%;
                height: 70%;
              }
            }

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              &::after {
                display: none;
              }
            }
          }
        }
        &__description {
          font-size: 21px;
          line-height: 26px;
          color: #323e48;
          font-weight: bold;
          font-family: "museo-slab", serif;

          @media screen and (min-width: 768px) {
            font-size: 24px;
            line-height: 30px;
          }

          @media screen and (min-width: 1280px) {
            font-size: 32px;
            line-height: 40px;
          }
          @media screen and (min-width: 1920px) {
            font-size: 48px;
            line-height: 55px;
          }

          p {
            margin: 0 0 10px;
            font-family: inherit;

            @media screen and (min-width: 768px) {
              margin: 25px 0;
            }
          }
        }
        &__link {
          font-size: 18px;
          line-height: 25px;
          text-decoration-line: underline;

          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
          @media screen and (min-width: 1280px) {
            font-size: 20px;
            line-height: 140%;
          }
          @media screen and (min-width: 1920px) {
            font-size: 25px;
            line-height: 40px;
          }
          a {
            color: #7b868c;
          }
        }
      }

      .consultation__link {
        margin-top: 30px;
        border-radius: 34px;
        outline: none;
        color: #fff;
        width: 100%;
        font-size: 18px;
        line-height: 100%;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        border: 0;
        padding: 0;

        a {
          padding: 11px 45px;
        }

        @media screen and (min-width: 768px) {
          margin-top: 40px;
          font-size: 18px;
          line-height: 21px;
        }

        @media screen and (min-width: 1280px) {
          width: 420px;

          a {
            padding: 19.5px 35px;
          }
        }

        @media screen and (min-width: 1920px) {
          margin-top: 60px;

          a {
            padding: 23px 61px;
          }
        }
      }
    }
    &__image {
      width: 100%;
      margin-top: 40px;

      @media screen and (min-width: 768px) {
       margin-top: 80px;

      }

      @media screen and (min-width: 1280px) {
        width: 49%;
        margin-top: 0;
      }

      @media screen and (min-width: 1920px) {
        width: 48%;
      }
      img {
        width: 100%;
        height: auto;
        filter: drop-shadow(45px 45px 40px rgba(50, 62, 72, 0.3));

        @media screen and (min-width: 1280px) {
         filter: drop-shadow(85px 85px 60px rgba(50, 62, 72, 0.3));
        }
      }
    }
  }
}
