section.random-movements
  opacity: 1

  svg
    z-index: 1
    position: fixed
    transition: all 20s linear
    pointer-events: none

    &.triangle
      // width: 40px
      // height: 40px
      transition: all 28s linear

      @media screen and (min-width: 992px)
        // width: 300px
        // height: 300px

      .st0
        stroke: #00ADD8 !important
        fill: transparent !important

    &.cross
      // width: 40px
      // height: 40px
      transition: all 40s linear

      @media screen and (min-width: 992px)
        // width: 100px
        // height: 100px

      .st0
        fill: transparent !important
        stroke: #93D500 !important

    // &.cross-white
    //   width: 50px
    //   height: 50px
    //   transition: all 35s linear

      .st0
        stroke-width: 5px !important
        fill: transparent !important
        stroke: white !important

    &.hexagon
      // width: 60px
      // height: 60px
      transition: all 32s linear

      @media screen and (min-width: 992px)
        // width: 600px
        // height: 600px

      .st0
        fill: transparent !important
        stroke: #993DBB
