section.items-section {
  width: 100%;
  margin-top: 65px;
  padding-bottom: 30px;
  background: #ffffff;
  box-shadow: 80px 80px 60px rgba(123, 134, 140, 0.135899);

  @media screen and (min-width: 1280px) {
    margin-top: 105px;
    padding-bottom: 80px;
  }

  .content {
    width: 80%;
    margin: 0 auto;

    .title {
      font-size: 22px;
      line-height: 120%;
      color: #323e48;
      font-family: "museo-slab", serif;

      @media screen and (min-width: 1280px) {
        font-size: 48px;
      }

      @media screen and (min-width: 1920px) {
        font-size: 65px;
        line-height: 1.08;
      }
    }

    .benefits-list {
      padding: 0;
      margin: 24px 0px 20px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      @media screen and (min-width: 1280px) {
        margin: 24px 0px 30px;
      }

      li {
        margin: 6px 0;
        display: flex;
        width: 100%;
        align-items: center;

        @media screen and (min-width: 768px) {
          width: 50%;
        }

        @media screen and (min-width: 1280px) {
          width: 25%;
          margin: 16px 0;
        }

        span svg {
          width: 20px;
          height: 20px;

          @media screen and (min-width: 1280px) {
            width: 32px;
            height: 32px;
          }

          @media screen and (min-width: 1920px) {
            width: 50px;
            height: 50px;
          }
        }

        .label {
          padding-left: 8px;
          font-size: 18px;
          line-height: 150%;
          color: #323e48;
          font-weight: bold;

          @media screen and (min-width: 1280px) {
            font-size: 20px;
            line-height: 140%;
          }

          @media screen and (min-width: 1920px) {
            font-size: 25px;
            line-height: 1.6;
          }
        }
      }
    }

    .link {
      font-size: 18px;
      line-height: 140%;
      text-decoration-line: underline;
      color: #7b868c;

      @media screen and (min-width: 1280px) {
        font-size: 20px;
        line-height: 140%;
      }
      @media screen and (min-width: 1920px) {
        font-size: 25px;
        line-height: 1.6;
      }
    }
  }
}
