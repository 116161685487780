.bottom__form-section {
  overflow: hidden;
  position: relative;

  .form_hexagon {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translate(-50%, 0%);

    @media screen and (min-width: 768px) {
      top: 0;
    }

    @media screen and (min-width: 1280px) {
      display: none;
    }
  }

  .form_cross {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, 0%);

    @media screen and (min-width: 768px) {
      top: 400px;
    }

    @media screen and (min-width: 1280px) {
      display: none;
    }
  }

  .form_triangle {
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: -1;

    @media screen and (min-width: 768px) {
      bottom: 0;
    }

    @media screen and (min-width: 1280px) {
      display: none;
    }
  }

  section.contact-us {
    padding-top: 35px;

    @media screen and (min-width: 768px) {
      padding-top: 85px;
    }

    @media screen and (min-width: 1280px) {
      padding-top: 135px;
    }

    .body-content .container {
      width: 80% !important;
      max-width: none;

      .contact-form_wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        @media screen and (min-width: 1280px) {
          flex-direction: row;
          margin-bottom: 50px;
        }

        .left-side,
        .right-side {
          width: 100%;
          max-width: none;
          margin-right: unset;
          text-align: center;

          @media screen and (min-width: 1280px) {
            width: 50%;
            text-align: left;
          }
        }

        .left-side {
          .title {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 27px;
          }

          .phone {
            margin: 0;

            &::before {
              width: 31px;
            }
          }

          p {
            font-size: 18px;
            line-height: 25px;
            color: #9ba2a7;
            margin-top: 25px;
            margin-bottom: 35px;
          }

          .social-icons span {
            font-size: 18px;
            line-height: 25px;
            display: flex;
            margin: 0 5%;
          }

          @media screen and (min-width: 768px) {
            .title {
              font-size: 42px;
              line-height: 45px;
              margin-bottom: 10px;
            }

            .phone::before {
              width: 47px;
            }

            p {
              font-size: 18px;
              line-height: 25px;
              color: #9ba2a7;
              margin-top: 25px;
              margin-bottom: 35px;
            }

            .social-icons {
              justify-content: center;

              span {
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                color: #7b868c;
              }

              svg {
                width: 28px;
                height: 28px;
              }
            }
          }

          @media screen and (min-width: 1280px) {
            .phone::before {
              width: 77px;
            }

            .title {
              font-size: 48px;
              line-height: 120%;
              margin-bottom: 27px;
            }

            p {
              font-size: 20px;
              line-height: 140%;
            }

            .social-icons {
              justify-content: left;
              span {
                color: #fff;
              }

              svg {
                width: 48px;
                height: 48px;
              }
            }
          }

          @media screen and (min-width: 1920px) {
            .title {
              font-size: 65px;
              line-height: 70px;
              margin-bottom: 27px;
            }

            p {
              font-size: 25px;
              line-height: 40px;
            }
          }
        }

        .right-side {
          @media screen and (min-width: 320px) and (max-width: 767px) {
            textarea {
              height: 140px;
            }
            .text-wrap {
              display: flex;
              flex-direction: column;

              div:last-of-type {
                order: 1;
                width: 100%;
              }
              div:first-of-type {
                order: 2;
                width: 100%;
                p {
                  font-size: 11px;
                  line-height: 18px;
                  color: #7b868c;
                  text-align: left;
                  margin: 0;
                }
              }

              .btn-submit {
                width: 100%;
                height: 40px;
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 32px;
              }
            }
          }
          @media screen and (min-width: 768px) and (max-width: 1279px) {
            margin-top: 70px;
            input {
              height: 50px;
            }

            textarea {
              height: 140px;
            }

            .text-wrap {
              display: flex;
              flex-direction: column;

              div:last-of-type {
                order: 1;
                width: 100%;
              }
              div:first-of-type {
                order: 2;
                width: 100%;
                p {
                  font-size: 14px;
                  line-height: 18px;
                  color: #7b868c;
                  text-align: left;
                  margin: 0;
                }
              }

              .btn-submit {
                width: 100%;
                height: 50px;
                font-size: 11px;
                line-height: 18px;
                margin-bottom: 50px;
              }
            }
          }
        }
      }
    }
  }

  .footer__content {
    @media screen and (min-width: 320px) {
      width: 80% !important;

      span,
      span a {
        color: #7b868c;
      }

      span:last-of-type {
        display: block;
        padding-left: 0 !important;
      }

      span:nth-of-type(1n + 2):not(:last-of-type) {
        border-right: 1px solid #7b868c !important;
      }

      span:nth-of-type(3):not(:last-of-type) {
        border-right: none !important;
      }
    }
    @media screen and (min-width: 768px) {
      span:nth-of-type(3):not(:last-of-type),
      span:first-of-type {
        border-right: 1px solid #7b868c !important;
      }

      span:nth-of-type(1n + 2):not(:last-of-type) {
        padding: 0 10px !important;
      }

      span:first-of-type {
        padding-left: 0px;
        padding-right: 10px;
      }

      span:last-of-type {
        display: inline-block;
        padding-left: 10px !important;
      }
    }
    @media screen and (min-width: 1280px) {
      span,
      span a {
        color: #fff !important;
      }
      span:nth-of-type(3):not(:last-of-type),
      span:nth-of-type(1n + 2):not(:last-of-type),
      span:first-of-type {
        border-right: 1px solid #fff !important;
      }

      span:nth-of-type(1n + 2):not(:last-of-type) {
        padding: 0 20px !important;
      }

      span:first-of-type {
        padding-left: 0px;
        padding-right: 20px;
      }

      span:last-of-type {
        display: inline-block;
        padding-left: 20px !important;
      }
    }
  }
}
