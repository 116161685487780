form.contact-form {

  .flex {

    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: space-between;

      .left, .right {
        width: 48%;
      }

      textarea.form-control {
        height: 285px;
      }
    }

  }

  * {
    color: #ffffff;
  }

  .form-group {
    position: relative;
    margin-bottom: 13px;

    @media screen and (min-width: 992px) {
      margin-bottom: 39px;
    }

    input {
      width: 100%;
      height: 31px;
      background-color: #7b868c;
      border-radius: 34px;
      border: none;
      font-size: 12px;
      padding: 20px;
      box-sizing: border-box;
      outline: none;
      color: white;
      font-weight: 500;

      &.light {
        background-color: #e8ebed;
        color: #323E48;

        &::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #323E48;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: #323E48;
        }

      }

      &::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
      }

      @media screen and (min-width: 992px) {
        text-indent: 15px;
        height: 70px;
        font-size: 22px;
        line-height: 1.59;
      }

    }
    textarea {
      font-weight: 500;
      border-radius: 34px;
      width: 100%;
      height: 193px;
      background-color: #7b868c;
      color: white;
      border: none;
      font-size: 18px;
      padding: 20px;
      box-sizing: border-box;
      resize: none;
      outline: none;
      border-radius: 34px;

      @media screen and (min-width: 992px) {
        padding: 20px 20px 20px 35px;
        font-size: 22px;
        line-height: 1.59;
      }

      &.light {
        background-color: #e8ebed;
        color: #323E48;

        &::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #323E48;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #323E48;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: #323E48;
        }

      }

      &::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
      }
    }
    .invalid-feedback {
      color: red;
      font-size: 14px;
      margin-top: 5px;

      @media screen and (min-width: 992px) {
        position: absolute;
      }
    }

  }

  .text-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    position: relative;

    p {
      font-size: 12px;
      max-width: 280px;
    }

    .btn-submit {
      font-weight: bold;
      width: 89px;
      height: 27px;
      border-radius: 34px;
      border: 2px solid white;
      white-space: nowrap;
      font-size: 12px;
      cursor: pointer;
      outline: none;
      background: white;
      color: black;
      transition: all 0.3s linear;

      &:hover {
        background-color: transparent;
        color: white;
      }

      @media screen and (min-width: 992px) {
        font-size: 18px;
        background-color: transparent;
        color: #fff;

        &:hover {
          background-color: white;
          color: black;

          span {
            color: black;
          }
        }
      }

      &.dark {
        color: #323e48;
        border-width: 2px;
        border-color: #323e48;
      }

      &:disabled {
        background-color: #6f882d;
        opacity: 0.8;
      }

      @media screen and (min-width: 992px) {
        width: 240px;
        height: 68px;
      }
    }

    .success-form {
      color: #94c021;
      font-size: 24px;
      text-align: right;
      position: absolute;
      right: 0;
    }
    .error-form {
      color: #e93434;
      font-size: 18px;
      text-align: right;
      position: absolute;
      right: 0;

      a {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .form-group {
      textarea {
        height: 85px;
        border-radius: 15px;
        font-size: 12px;
      }
    }
    .text-wrap {
      padding-top: 0px;

      div:last-of-type {
        order: 1;
        width: 100%;
      }
      div:first-of-type {
        order: 2;
        width: 100%;
        p {
          font-size: 11px;
          line-height: 18px;
          color: #7b868c;
          text-align: left;
          margin: 0;
        }
      }

      .btn-submit {
        width: 100%;
        height: 40px;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 32px;
      }

      p {
        max-width: 100%;
        margin: 0 0 10px;
      }

      .success-form  {
        right: auto;
        left: 0;
        text-align: left;
      }
      .error-form  {
        right: auto;
        left: 0;
        text-align: left;
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}
