.filter-tabs {
  width: 100%;
  margin-bottom: 40px;
  z-index: 20;

  @media screen and (min-width: 768px) {
    margin-bottom: 100px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 150px;
  }

  .tabs__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: inherit;

    .categories__tabs {
      display: none;
      @media screen and (min-width: 768px) {
        display: block;
      }
      .tab__btn {
        width: auto;
        height: 38px;
        margin: 0 5px;
        padding: 0 30px;
        border-radius: 34px;
        border: solid 2px #323e48;
        background-color: #fff;
        cursor: pointer;
        outline: none;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #323e48;
        transition: all 0.2s linear;

        @media screen and (min-width: 1280px) {
          width: 180px;
          height: 56px;
          padding: 17px 17px;
          margin: 0 15px;
        }

        @media screen and (min-width: 1920px) {
          height: 68px;
          padding: 23px 17px;
        }

        &:last-of-type {
          width: auto;
          margin: 0;
          position: absolute;
          right: 0;

          @media screen and (min-width: 1280px) {
            width: 120px;
          }
        }

        &:first-of-type {
          margin-left: 0;
        }

        &.active,
        &:hover {
          background-color: #323e48;
          color: #fff;
        }
      }
    }

    .categoties__select {
      width: 100%;
      @media screen and (min-width: 768px) {
        display: none;
      }

      // .select {
      //   width: 100%;
      //   outline: none;
      //   border: 2px solid #323e48;
      //   border-radius: 20px;
      //   height: 40px;
      //   option {
      //     text-align: center;
      //   }
      // }
      .select-box {
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;

        .options-container {
          background-color: #fff;
          color: #323e48;
          max-height: 0;
          width: 100%;
          opacity: 0;
          transition: all 0.2s;
          border: 2px solid #323e48;
          border-radius: 20px;
          overflow: hidden;
          position: absolute;
          top: 50px;
          left: 0;

          &.active {
            max-height: unset;
            opacity: 1;
            z-index: 20;

            & + .selected::after {
              transform: rotateX(180deg);
              top: 0px;
            }
          }

          .option {
            padding: 20px 24px;
            cursor: pointer;
            font-size: 18px;
            line-height: 18px;
            font-family: "proxima-nova", sans-serif;
            text-align: center;
            color: #9ba2a7;

            &:hover {
              color: #323e48;
            }

            .radio {
              display: none;
            }

            label {
              cursor: pointer;
            }
          }
        }

        .selected {
          width: 100%;
          height: 40px;
          background-color: #fff;
          color: #323e48;
          position: relative;
          order: 0;
          border: 2px solid #323e48;
          border-radius: 20px;
          padding: 12px 24px;
          cursor: pointer;
          font-size: 18px;
          line-height: 16px;
          font-family: "proxima-nova", sans-serif;
          text-align: center;

          &::after {
            content: "";
            background: url("../../../assets/images/arrow-down.svg");
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            height: 100%;
            width: 32px;
            top: 3px;
            right: 22px;
            transition: all 0.4s;
          }
        }
      }
    }
  }
}
