section.our-values
  margin-bottom: 100px
  @media screen and (max-width: 1529px)
    overflow: hidden

  @media screen and (min-width: 992px)
    margin-bottom: 250px

  @media screen and (min-width: 1530px)
    height: 400vh
    margin-bottom: 300px

  .wrapper
    //position: fixed
    height: 100%
    width: 100%
    // top: 0
