.category-item {
  width: 100%;
  padding-bottom: 95px;

  @media screen and (min-width: 768px) {
    padding-bottom: 112px;
  }

  @media screen and (min-width: 1920px) {
    padding-bottom: 208px;
  }

  .item__content {
    display: flex;
    flex-direction: column;

    .content__image {
      position: relative;
      overflow: hidden;
      display: flex;
      box-shadow: 30px 30px 30px 0px rgba(128, 128, 128, 0.3);

      @media screen and (min-width: 768px) {
        box-shadow: 40px 40px 40px 0px rgba(128, 128, 128, 0.3);
      }

      @media screen and (min-width: 1280px) {
        box-shadow: 50px 50px 50px 0px rgba(128, 128, 128, 0.3);
      }

      @media screen and (min-width: 1920px) {
        box-shadow: 80px 80px 50px 0px rgba(128, 128, 128, 0.3);
      }

      img {
        width: 100%;
        height: auto;
        transition: transform 0.5s ease;
      }

      .content__btn {
        position: absolute;
        top: 15px;
        right: 5%;
        width: auto;
        height: auto;
        padding: 2px 15px;
        border-radius: 34px;
        background-color: #ffffff;
        font-family: "proxima-nova", sans-serif;
        color: #323e48;
        text-align: center;
        font-weight: bold;
        font-size: 11px;
        line-height: 18px;

        @media screen and (min-width: 768px) {
          font-size: 18px;
          line-height: 19px;
          padding: 4px 40px;
          top: 16px;
        }

        @media screen and (min-width: 1280px) {
          top: 20px;
          font-size: 18px;
          padding: 12px 16px;
          width: 140px;
        }

        @media screen and (min-width: 1920px) {
          top: 40px;
          font-size: 18px;
          padding: 14px 16px;
          width: 166px;
          height: 47px;
        }
      }
    }

    .title__link {
      text-decoration: none;
    }

    .content__title {
      margin: 0;
      margin-top: 40px;
      font-family: "museo-slab", serif;
      font-weight: bold;
      color: #323e48;
      font-size: 21px;
      line-height: 24px;

      @media screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 130%;
      }

      @media screen and (min-width: 1280px) {
        font-size: 48px;
        line-height: 55px;
      }

      @media screen and (min-width: 1920px) {
        margin-top: 80px;
      }
    }

    &.hovered {
      .content__image {
        img {
          transform: scale(1.08);
        }
      }
    }

    .content__description,
    a {
      font-family: "proxima-nova", sans-serif;
      font-weight: 500;
      color: #7b868c;
      margin-top: 16px;
      font-size: 18px;
      line-height: 150%;

      @media screen and (min-width: 1280px) {
        font-size: 28px;
        line-height: 40px;
        margin-top: 30px;
      }
    }

    a {
      font-weight: 700;
      transition: all 0.2s linear;

      @media screen and (min-width: 768px) and (max-width: 1279px) {
        font-size: 20px;
        line-height: 40px;
      }

      &:hover {
        color: #323e48;
      }

      @media screen and (min-width: 1280px) {
        margin-top: 0;
      }
    }

    .content__date {
      margin-top: 25px;
      font-size: 16px;
      font-weight: 500;
      color: #7b868c;

      @media screen and (min-width: 1280px) {
        margin-top: 60px;
        font-size: 20px;
      }
    }

    .content__description p {
      margin: 0;
    }
  }

  &.date {
    .content__title {
      margin-top: 0;

      @media screen and (min-width: 1280px) {
        margin-top: 20px;
      }

      @media screen and (min-width: 1920px) {
        margin-top: 40px;
      }
    }
  }
}
