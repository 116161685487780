.btn_to_top {
    position: fixed;
    right: 50px;
    bottom: 100px;
    width: 50px;
    height: 50px;
    border: 1.7px solid #323e48;
    border-radius: 50%;
    display: none;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s linear;

    @media screen and (min-width: 1280px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (min-width: 1920px) {
      right: 80px;
    }

    &.active {
      opacity: 1;
    }

    &.white {
      border: 1.7px solid #fff;

      svg path:first-of-type {
        fill: #fff !important;
      }
    }

    svg {
      width: 40px;
      height: auto;
      transform: rotateX(180deg);
    }
  }