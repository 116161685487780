.consultation-section {
  width: 100%;
  background: #ffffff;
  box-shadow: 80px 80px 60px rgba(123, 134, 140, 0.135899);

  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 45px 0px;
    text-align: center;

    @media screen and (min-width: 1280px) {
      padding: 175px 0 90px;
    }

    @media screen and (min-width: 1920px) {
      padding: 175px 0 260px;
    }

    .title {
      max-width: 950px;
      margin: 0 auto;

      h2 {
        margin: 0;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        font-size: 25px;
        line-height: 30px;
        color: #323e48;
        font-family: "museo-slab", serif;

        @media screen and (min-width: 768px) {
          font-size: 34px;
          line-height: 130%;
        }

        @media screen and (min-width: 1280px) {
          font-size: 48px;
          line-height: 120%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 65px;
          line-height: 1.08;
        }
      }
    }

    .description {
      margin-top: 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #7b868c;

      b {
        color: black;
      }

      a {
        color: #7b868c;
      }

      @media screen and (min-width: 1280px) {
        font-size: 20px;
        line-height: 140%;
      }

      @media screen and (min-width: 1920px) {
        margin-top: 55px;
        font-size: 25px;
        line-height: 40px;
      }

      span {
        color: #323e48;
      }
    }

    .consultation_btn {
      margin-top: 20px;

      @media screen and (min-width: 768px) {
        margin-top: 45px;
      }

      @media screen and (min-width: 1920px) {
        margin-top: 55px;
      }

      .btn {
        text-align: center;
        border-radius: 34px;
        outline: none;
        font-size: 18px;
        line-height: 100%;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        border: 0;
        padding: 0;

        a {
          padding: 11px 30px;
        }

        @media screen and (min-width: 768px) {
          font-size: 18px;
          line-height: 21px;

          a {
            padding: 12px 30px;
          }
        }

        @media screen and (min-width: 1280px) {
          width: 360px;

          a {
            padding: 12px 35px;
          }
        }

        @media screen and (min-width: 1920px) {
          a {
            padding: 23px 35px;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
