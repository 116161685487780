section.member
  margin-bottom: 100px

  @media screen and (min-width: 992px)
    margin-bottom: 200px

  .content
    width: 90%
    margin: 0 auto
    padding-top: 150px
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between

    @media screen and (min-width: 992px)
      margin: 0 auto
      width: 80%

    @media screen and (min-width: 1200px)
      padding-top: 200px
      flex-direction: row
      justify-content: space-between

    .text
      text-align: center
      margin-bottom: 50px

      @media screen and (min-width: 768px)
        width: 80%

      @media screen and (min-width: 1200px)
        margin-bottom: 0
        text-align: left
        width: 55%

      a
        &:not(.btnLink)
          color: #323e48
          @media screen and (max-width: 1200px)
            margin-right: 10px

      .btnDiv
        font-size: 18px

      .name
        display: flex
        align-items: center
        justify-content: center

        @media screen and (min-width: 1200px)
          justify-content: flex-start

        .author__link
          width: 40px
          height: 40px
          margin-right: 10px
          display: flex
          justify-content: center
          align-items: center

          @media screen and (min-width: 1280px)
            width: 66.76px
            height: 58px

          svg
            position: absolute

          img
            width: 20px
            height: auto

            @media screen and (min-width: 1280px)
              width: 30px
              height: auto

        h4
          font-weight: 400
          display: inline
          color: #7b868c
          font-size: 16px
          margin: 0

          @media screen and (min-width: 992px)
            font-size: 25px

      h2, h3
        color: #323e48
        font-size: 21px
        line-height: 24px
        font-family: "museo-slab", serif !important

        @media screen and (min-width: 992px)
          font-size: 60px
          line-height: 70px
          margin-top: 20px

      p
        font-weight: 500
        color: #323e48
        font-size: 18px
        line-height: 25px
        margin: 0 auto
        max-width: 80%

        @media screen and (max-width: 992px)
          &:not(:last-of-type)
            margin-bottom: 15px

        @media screen and (min-width: 992px)
          font-size: 22px
          line-height: 35px
          margin: 20px auto
          max-width: unset

      .btn__wrapper
        display: flex
        flex-direction: column

        button, .consultation__link
          font-weight: bold
          margin-top: 50px
          padding: 20px 40px
          border-radius: 34px
          border: none
          outline: none
          color: white
          font-size: 12px

          @media screen and (min-width: 992px)
            font-size: 18px

          &:hover
            cursor: pointer

        .consultation__link
          margin: 50px auto 0
          text-decoration: none
          max-width: 80%


        h5
          font-weight: 500
          color: #7b868c
          font-size: 12px

          @media screen and (min-width: 992px)
            font-size: 18px

    .photo
      order: -1
      margin-bottom: 45px

      @media screen and (min-width: 1200px)
        width: 40%
        order: 0
        margin: 5% 0 0

      img
        width: 120%
