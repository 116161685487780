section.team-timeline
  margin-top: 150px

  .section-title
    margin-bottom: 100px
    font-size: 21px
    line-height: 24px
    text-align: center
    color: #323e48

    @media screen and ( min-width: 992px)
      margin-bottom: 215px
      font-size: 48px
      line-height: 1.15

  /* The actual timeline (the vertical ruler) */
  .wrapper
    position: relative
    max-width: 1200px
    margin: 0 auto

    &:after
      content: ''
      position: absolute
      width: 2px
      background-color: #a2a9ad
      top: 0px
      //bottom: 200px
      height: var(--height)
      left: 50%
      margin-left: -1px

      @media screen and ( max-width: 991px )
        top: -20px

  .container
    margin-bottom: 175px
    padding: 10px 0px
    position: relative
    background-color: inherit
    width: 50%

    @media screen and (min-width: 1600px)
      width: 60%

    &:after
      content: ''
      position: absolute
      width: 30px
      height: 50px
      right: -33px
      top: -10px
      z-index: 1
      background-size: contain
      background-position: center center
      background-repeat: no-repeat

      @media screen and (min-width: 992px)
        width: 65px

    h2
      font-family: "museo-slab", serif

    &.left
      left: -1px
      text-align: right
      padding: 0 60px 0 24px

      @media screen and (min-width: 1600px)
        left: calc(-10% - 1px)
        padding: 0px 84px 0px 0px

    &.right
      left: calc(50% + 1px)
      padding: 0 24px 0 60px

      @media screen and (min-width: 1600px)
        padding: 0px 0px 0px 84px

      &:after
        left: -33px

    &:nth-child(1)
      &:after
        background-image: url("../../../assets/images/new/Team Images/triangle-yellow.svg")
      h2
        color: #ffcc4d
    &:nth-of-type(2)
      &:after
        background-image: url("../../../assets/images/new/Our process/cross-green.svg")
      h2
        color: #93d500
    &:nth-of-type(3)
      &:after
        background-image: url("../../../assets/images/new/Our process/hexagon-purple.svg")
      h2
        color: #993dbb
    &:nth-of-type(4)
      &:after
        background-image: url("../../../assets/images/new/Our process/circle-blue.svg")
      h2
        color: #00acd8

  .content
    margin-top: 20px
    h3.number
      margin: 0
      color: white
      font-size: 21px

      @media screen and (min-width: 992px)
        font-size: 36px
        margin-top: 25px

    h2
      font-size: 21px
      line-height: 24px
      margin-top: 0

      @media screen and (min-width: 992px)
        font-size: 36px
        line-height: 30px

    p
      font-size: 18px
      line-height: 25px
      color: #323e48
      max-width: 80%
      margin: 0 auto

      @media screen and (min-width: 992px)
        font-size: 22px
        line-height: 1.59
        max-width: 640px
        margin: unset

  @media screen and (max-width: 992px)
    .container
      &.left:after, &.right:after
        left: auto
        display: block
        width: 100%
        right: auto
        transform: scale(0.4)
        padding: 15px 0

  @media screen and (max-width: 992px)
    .timeline::after
      left: 50%
      bottom: 155px

    .container
      width: 100%
      padding-left: 0
      padding-right: 0
      z-index: 1

      &.left, &.right
        text-align: center
        padding: 0

      &:after
        top: -60px
        height: 50px
        background-size: auto

      &.right
        left: 0%
