.related-articles {
  width: 100%;
  padding-top: 80px;
  overflow: hidden;

  .content {
    width: 80%;
    margin: 0 auto;

    h2 {
      font-size: 25px;
      line-height: 30px;
      color: #323e48;
      font-family: "museo-slab", serif;

      @media screen and (min-width: 768px) {
        font-size: 34px;
        line-height: 130%;
      }

      @media screen and (min-width: 1280px) {
        font-size: 48px;
        line-height: 120%;
      }

      @media screen and (min-width: 1920px) {
        font-size: 65px;
        line-height: 70px;
      }
    }

    .articles-container {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }

      @media screen and (min-width: 1920px) {
        margin-top: 90px;
      }

      .category-item {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 47%;
        }

        @media screen and (min-width: 1280px) {
          width: 45%;
        }
      }
    }
  }
}
