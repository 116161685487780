
a {
    text-decoration: none;
    &:hover{
        text-decoration: underline;

    }
}

ul, ol {
    li {
      font-weight: 500;
      font-size: 20px;
      margin: 1%;
    }
}
