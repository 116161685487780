  section.contact-us {
    padding-bottom: 50px;

  @media screen and (min-width: 992px) {
    // margin-top: 100px;
    padding-top: 300px;
    padding-bottom: 130px;
  }

  * {
    color: #ffffff;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin: 0 5%;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  .body-content {
    position: relative;

    .container {
      max-width: 1164px;
      width: 90%;
      margin: 0 auto;
      position: relative;
      // overflow: hidden;

      .social-icons {
        display: flex;
        align-items: center;

        span {
          margin-right: 10px;
          font-size: 12px;

          @media screen and (min-width: 992px) {
            font-size: 18px;
          }
        }

        ul {
          li {
            display: inline-block;
            margin-right: 15px;
            &:last-of-type {
              margin-right: 0;
            }

            a {
              transition: all 0.3s ease-in-out;

              @media screen and (min-width: 768px) {
                opacity: 0.3;
              }

              &:hover {
                opacity: 1;
              }

              svg {
                width: 30px;
                height: 30px;

                @media screen and (min-width: 768px) {
                  width: 48px;
                  height: 48px;
                }
                &.dark path.st0 {
                  fill: #a2a9ad !important;
                }
              }
            }
          }
        }

        @media (max-width: 680px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          top: 18px;
          margin-bottom: 45px;
        }
      }

      .contact-form_wrap {
        display: flex;
        justify-content: space-between;


        .left-side {
          margin-right: 69px;
          max-width: 527px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            margin-top: 0;
            font-size: 30px;
            line-height: 24px;
            font-family: "museo-slab", serif;

            @media screen and (min-width: 992px) {
              font-size: 60px;
              line-height: 70px;
            }
          }

          h2:not(.title) {
            font-family: "museo-slab", serif;

            &.phone {
              &:before {
                background-color: white;
              }
            }

            &.type {
              &:before {
                background-color: #a2a9ad;
              }
            }

            &:before {
              content: "";
              display: inline-block;
              width: 77px;
              height: 2px;
              background-color: white;
              vertical-align: middle;
              margin-right: 10px;
            }

            a,
            span {
              color: #93d500;
              font-size: 20px;
              font-family: "museo-slab", serif;

              @media screen and (min-width: 992px) {
                font-size: 35px;
              }
            }
          }

          p {
            font-size: 25px;
            line-height: 40px;
            margin: 50px 0 80px 0;
            //font-family: "museo-slab", serif;
          }

          .title {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 27px;
          }

          .phone {
            margin: 0;

            &::before {
              width: 31px;
            }
          }

          p {
            font-size: 18px;
            line-height: 25px;
            color: #9ba2a7;
            margin-top: 25px;
            margin-bottom: 35px;
          }

          .social-icons span {
            font-size: 18px;
            line-height: 25px;
            display: flex;
            margin: 0 5%;
          }

          @media screen and (min-width: 768px) {
            .title {
              font-size: 42px;
              line-height: 45px;
              margin-bottom: 10px;
            }

            .phone::before {
              width: 47px;
            }

            p {
              font-size: 18px;
              line-height: 25px;
              color: #9ba2a7;
              margin-top: 25px;
              margin-bottom: 35px;
            }

            .social-icons {
              justify-content: center;

              span {
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                color: #7b868c;
              }

              svg {
                width: 28px;
                height: 28px;
              }
            }
          }

          @media screen and (min-width: 1280px) {
            .phone::before {
              width: 77px;
            }

            .title {
              font-size: 48px;
              line-height: 120%;
              margin-bottom: 27px;
            }

            p {
              font-size: 20px;
              line-height: 140%;
            }

            .social-icons {
              justify-content: left;
              span {
                color: #fff;
              }

              svg {
                width: 48px;
                height: 48px;
              }
            }
          }

          @media screen and (min-width: 1920px) {
            .title {
              font-size: 65px;
              line-height: 70px;
              margin-bottom: 27px;
            }

            p {
              font-size: 25px;
              line-height: 40px;
            }
          }
        }
        .right-side {
          max-width: 568px;
          width: 100%;
        }

        @media (max-width: 956px) {
          text-align: center;

          .left-side {
            margin-right: 25px;
          }
        }
      }
      @media (max-width: 680px) {
        .contact-form__title {
          flex-wrap: wrap;
          flex-direction: row;
          align-items: flex-start;

          h3 {
            width: 100%;
            order: 1;
          }
          p {
            text-align: left;
            margin-bottom: 85px;
          }
        }
        .contact-form_wrap {
          flex-wrap: wrap;

          .left-side {
            max-width: 100%;
            margin-right: 0;

            p {
              font-size: 18px;
              line-height: 25px;
              margin: 25px 0 35px 0;
            }
          }
          .right-side {
            max-width: 100%;

            .text-wrap {
              display: flex;
              flex-direction: column;

              p {
                max-width: 100%;
              }
              .error-form {
                text-align: left;
                position: relative;
              }
              .success-form {
                text-align: center;
                width: 100%;
                position: relative;
              }

              div:last-of-type {
                order: 1;
                width: 100%;
              }
              div:first-of-type {
                order: 2;
                width: 100%;
                p {
                  font-size: 11px;
                  line-height: 18px;
                  color: #7b868c;
                  text-align: left;
                  margin: 0;
                }
              }

              .btn-submit {
                width: 100%;
                height: 40px;
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 32px;
              }
            }
          }
        }
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}
