section.service-section {
  width: 100%;
  padding-top: 60px;

  @media screen and (min-width: 768px) {
    padding-top: 95px;
  }

  @media screen and (min-width: 1920px) {
    padding-top: 160px;
  }

  .content {
    width: 80%;
    margin: 0 auto;

    ._content {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .text {
        width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;

        @media screen and (min-width: 768px) {
          width: 49%;
        }

        .section-title {
          font-size: 24px;
          line-height: 130%;
          color: #323e48;
          font-family: "museo-slab", serif;
          margin-bottom: 20px;

          @media screen and (min-width: 768px) {
            margin: 0;
          }

          @media screen and (min-width: 1280px) {
            font-size: 48px;
            line-height: 120%;
            margin-bottom: 60px;
          }
          @media screen and (min-width: 1920px) {
            font-size: 65px;
            line-height: 1.08;
          }
        }

        p {
          margin: 0;
          font-size: 18px;
          line-height: 150%;
          color: #323e48;

          @media screen and (min-width: 1280px) {
            font-size: 20px;
            line-height: 140%;
          }
          @media screen and (min-width: 1920px) {
            font-size: 22px;
            line-height: 40px;
          }
        }

        a:not(.btnLink) {
          color: #323e48;
        }

        @media screen and (min-width: 768px) {
          width: 42%;
        }

        .consultation__link {
          text-decoration: none;
          margin-top: 32px;
          border-radius: 34px;
          outline: none;
          padding: 10px 14px;
          font-size: 18px;
          line-height: 19px;
          font-weight: bold;
          text-align: center;
          color: #fff;
          border: 0;

          @media screen and (min-width: 768px) {
            padding: 13px 14px;
          }

          @media screen and (min-width: 1280px) {
            font-size: 18px;
            line-height: 22px;
            padding: 13px 26px;
            width: 370px;
          }

          @media screen and (min-width: 1920px) {
            padding: 22px 26px;
            margin-top: 50px;
          }
        }
      }

      .image {
        margin-top: 30px;
        width: 100%;
        @media screen and (min-width: 768px) {
          width: 50%;
          margin-top: unset;
        }

        img {
          width: 100%;
          height: auto;
          filter: drop-shadow(45px 45px 40px rgba(123, 134, 140, 0.3));

          @media screen and (min-width: 1280px) {
             filter: drop-shadow(85px 85px 60px rgba(123, 134, 140, 0.3));
          }
        }
      }
    }

    .content_second {
      align-items: flex-start;
      @media screen and (min-width: 768px) {
        margin-top: 100px;
      }

      @media screen and (min-width: 1280px) {
        margin-top: 150px;
      }
      .second_text {
        order: 2;
        margin-top: 30px;
        align-self: flex-start;

        @media screen and (min-width: 768px) {
          margin-top: unset;
        }
      }
      .second_image {
        order: 1;
      }
    }

    .content_third {
      margin-top: 30px;
      @media screen and (min-width: 768px) {
        margin-top: 100px;
      }

      .text {
        width: 100%;
        @media screen and (min-width: 768px) {
          width: 50%;
        }

        @media screen and (min-width: 1280px) {
          margin-top: 100px;
        }
      }

      .image {
        width: 100%;
        @media screen and (min-width: 768px) {
          width: 42%;
        }
        img {
          width: 100%;
          height: auto;

          @media screen and (min-width: 1280px) {
            margin-top: -100px;
          }
        }
      }

      @media screen and (max-width: 767px) {
        .third_image {
          order: 1;
        }

        .third_text {
          order: 2;
          margin-top: 30px;
        }
      }
    }
  }

  .service-footer {
    padding-bottom: 200px;

    a.link {
      color: #323e48;
    }

    @media screen and (max-width: 1220px) {
      padding-bottom: 80px;
    }
  }
}
