section.our-consultant
  margin-bottom: 200px
  min-height: 100vh

  .content
    width: 90%
    margin: 0 auto
    padding-top: 150px
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between

    @media screen and (min-width: 992px)
      margin: 0 auto
      padding-top: 200px
      width: 80%

    @media screen and (min-width: 1200px)
      flex-direction: row
      justify-content: space-between

    .text
      text-align: center
      margin-bottom: 50px

      @media screen and (min-width: 1200px)
        margin-bottom: 0
        text-align: left
        width: 50%

      @media screen and (min-width: 1600px)
        width: 43%

      .mouse
        margin-top: 50px
        display: flex
        align-items: center
        justify-content: center

        @media screen and (min-width: 1200px)
          margin-top: 80px
          justify-content: start

        p
          margin: 0 0 0 23px

        svg
          cursor: pointer
          width: 24px
          height: 34px

          @media screen and (min-width: 992px)
            width: 48px
            height: 69px

          g#Index-Landing---imaginative
            fill: black

      h4
        font-weight: 500
        color: #7b868c
        font-size: 11px
        margin: 0

        @media screen and (min-width: 992px)
          font-size: 25px
          line-height: 35px

      h2
        font-family: "museo-slab", serif
        color: #323e48
        font-size: 21px
        line-height: 24px
        margin-bottom: 0

        @media screen and (min-width: 992px)
          margin-top: 30px
          font-size: 60px
          line-height: 1.17

      p
        font-weight: 500
        color: #323e48
        font-size: 18px
        line-height: 25px

        @media screen and (min-width: 992px)
          margin: 46px 0 0
          font-size: 22px
          line-height: 1.59

        b
          font-size: 18px

          @media screen and (min-width: 992px)
            font-size: 25px

        span
          font-weight: bold

    .photo

      @media screen and (min-width: 1200px)
        width: 45%

      @media screen and (min-width: 1600px)
        width: 50%

      img
        width: 100%

// .drop-shadow
//   filter: drop-shadow(4.2rem 4.28rem 1.2rem #2b2b2b33)
