section.hero-hexagon
    @media screen and (min-width: 992px)
        min-height: 100vh

    .content
        margin: 0 auto
        display: flex
        flex-direction: column
        min-height: 100vh
        padding-top: 100px

        @media screen and (min-width: 992px)
            margin: 0 auto
            padding-top: 150px
            min-height: 920px
            margin-bottom: 200px
            align-items: center

        @media screen and (min-width: 1530px)
            padding-top: 200px
            flex-direction: row
            flex-wrap: wrap
            justify-content: space-between
            margin-bottom: 0

        @media screen and (min-width: 1600px)
            width: 90%

        .info
            max-width: 808px
            margin: 0 auto
            text-align: center

            @media screen and (min-width: 992px)
                margin: 0 auto

            @media screen and (min-width: 1530px)
                text-align: left
                margin-left: 0

            @media screen and (min-width: 1530px) and (max-width: 1700px)
                max-width: 750px

            @media screen and (max-width: 500px)
                padding: 0 5%

            h1
                font-size: 21px
                line-height: 24px
                margin-bottom: 20px
                color: white
                text-align: center

                @media screen and (min-width: 992px)
                    font-size: 65px
                    line-height: 70px
                    margin-bottom: 58px

                @media screen and (min-width: 1530px)
                    text-align: left

            h2
                font-weight: 400
                font-size: 16px
                line-height: 25px
                color: #a2a9ad
                text-align: center
                letter-spacing: initial

                @media screen and (min-width: 992px)
                    font-size: 35px
                    line-height: 50px

                @media screen and (min-width: 1530px)
                    text-align: left

                b
                    color: white

        .figures
            position: fixed
            width: 300px
            max-width: 466px
            margin: 70px auto 0 auto
            order: 1
            z-index: 1

            @media screen and (min-width: 1530px)
                position: fixed
                opacity: 0 !important
                top: 50%
                right: 10%

            &.in
                opacity: 1 !important
                position: fixed
                top: 5%
                right: 10%

                #hexagon
                    opacity: 1

                @media screen and (max-width: 1529px)
                    top: 50%
                    left: 50%
                    right: unset
                    -webkit-transform: translate(-50%, -30%)
                    transform: translate(-50%, -30%)

                @media screen and (max-width: 991px)
                    top: 50%
                    left: 50%
                    right: unset
                    -webkit-transform: translate(-50%, -20%)
                    transform: translate(-50%, -20%)

            @media screen and (min-width: 992px)
                // margin: 200px auto
                width: 57%

            @media screen and (min-width: 1500px)
                order: 0
                margin: 200px auto
                display: flex

            #hexagon
                width: 390px
                opacity: 0
                transition: opacity 0.5s linear

                @media screen and (min-width: 992px)
                    width: 620px

            .diamond
                position: absolute
                top: -20px
                left: 45px

                @media screen and ( max-width: 991px  )
                    width: 60px !important

                @media screen and (min-width: 992px)
                    top: -55px
                    left: 50px

            .triangle
                position: absolute
                top: -25px
                right: 50px

                @media screen and (min-width: 992px)
                    top: -65px
                    right: 50px

            .cross
                position: absolute
                top: 30%
                right: -25px

                @media screen and (min-width: 992px)
                    top: 27.5%
                    right: -55px

            .circle
                position: absolute
                bottom: 70px
                right: 50px

                @media screen and (min-width: 992px)
                    bottom: 100px
                    right: 55px

            .hexagon
                position: absolute
                top: 28%
                left: -20px

                @media screen and (min-width: 992px)
                    top: 26%
                    left: -45px

            .square
                position: absolute
                bottom: 70px
                left: 50px

                @media screen and (min-width: 992px)
                    bottom: 100px
                    left: 70px

            .diamond, .triangle, .cross, .circle, .square, .hexagon
                width: 50px
                height: auto

                @media screen and (min-width: 992px)
                    width: auto
                    height: auto
