section.hero-x
    min-height: 100vh
    @media screen and (min-width: 992px)
        min-height: 1200px

    .content
        width: 90%
        margin: 0 auto
        padding-top: 50px
        min-height: 100vh
        display: flex
        flex-direction: column

        @media screen and (min-width: 992px)
            margin: 0 auto
            width: 80%
            min-height: 920px
            margin-bottom: 200px

        @media screen and (min-width: 1530px)
            padding-top: 30px
            flex-direction: row
            flex-wrap: wrap
            justify-content: space-between
            margin-bottom: 0

        .info
            max-width: 800px
            margin: 0 auto
            text-align: center

            @media screen and (min-width: 992px)
                margin: 200px auto 0

            @media screen and (min-width: 1530px)
                text-align: left
                margin-left: 0

            h1
                font-size: 21px
                line-height: 24px
                margin-bottom: 20px
                color: #323e48

                @media screen and (min-width: 992px)
                    font-size: 60px
                    line-height: 70px
                    margin-bottom: 58px

            h2
                font-weight: 400
                font-size: 16px
                line-height: 25px
                color: #7b868c
                letter-spacing: initial

                @media screen and (min-width: 992px)
                    font-size: 35px
                    line-height: 1.43

                b
                    color: #323e48

                strong
                    display: none

                    @media screen and (min-width: 992px)
                        display: inline

            p
                font-size: 16px
                line-height: 25px
                color: #7b868c

                @media screen and (min-width: 992px)
                    font-size: 35px
                    line-height: 1.43

            .review
                h2.number
                    margin: 0
                    font-size: 32px
                    font-weight: bold
                    color: #93d500

                    @media screen and (min-width: 992px)
                        font-size: 80px

                span
                    font-size: 12px
                    color: #323e48
                    margin: 0 40px 0 20px
                    max-width: 90px

                @media screen and (min-width: 992px)
                    font-size: 17.5px

        .figures
            position: fixed
            width: 300px
            max-width: 466px
            margin: 70px auto 0 auto
            order: 1
            z-index: 0

            @media screen and (min-width: 1530px)
                transition: all 0.5s linear
                position: fixed
                opacity: 0 !important
                top: 50%
                right: 10%
                align-self: center
                text-align: center
                display: flex
                justify-content: center

            &.in
                opacity: 1 !important
                position: fixed
                top: 0
                right: 10%

                img.x-green
                    opacity: 1

                @media screen and (max-width: 1529px)
                    top: 50%
                    left: 50%
                    right: unset
                    -webkit-transform: translate(-50%, -20%)
                    transform: translate(-50%, -20%)

                @media screen and (min-width: 1530px)
                    &:before
                        content: ''
                        display: block
                        position: absolute
                        top: -65%
                        height: 400px
                        width: 300px
                        background-image: url("../../../assets/images/new/Client Commitment/Hexagon-grey-new.svg")
                        background-size: contain
                        background-repeat: no-repeat
                        background-position: 100%

                    &:after
                        content: ''
                        display: block
                        position: absolute
                        bottom: -60%
                        height: 400px
                        width: 300px
                        background-image: url("../../../assets/images/new/Client Commitment/Triangle-grey.svg")
                        background-size: contain
                        background-repeat: no-repeat
                        background-position: 100%

            @media screen and (min-width: 992px)
                width: 57%

            @media screen and (min-width: 1530px)
                order: 0
                margin: 200px auto
                display: flex

            img.x-green
                width: 100%
                opacity: 0
                transition: all .5s linear

            img.small-figures
                position: absolute
                width: 25px
                height: 25px
                z-index: 3

                @media screen and (min-width: 992px)
                    width: 35px
                    height: 35px

            .circle-center
                top: 35%
                left: 46.5%

            .circle-bottom
                bottom: 25%
                right: 14%

            .hexagon-top
                top: 15.5%
                left: 26%

            .hexagon-center
                top: 59%
                left: 46.5%

            .triangle-left
                top: 25%
                left: 15%

            .triangle-right
                top: 14%
                right: 25%

            .triangle-center
                top: 46%
                right: 33%

            .square-bottom
                bottom: 14%
                left: 26%

            .x-left
                bottom: 25%
                left: 15%

            .x-right
                top: 26%
                right: 15%

            .x-center
                top: 47%
                left: 33%

            .x-bottom
                bottom: 14%
                right: 25%
