section.client
  background-color: white
  overflow: hidden

  @media screen and (min-width: 992px)
    padding-bottom: 250px

  @media screen and (min-width: 1530px)
    // height: 200vh
    padding-bottom: 0

  .wrapper
    width: 100%
    height: 100%
