.nav-slider
      width: 100%
      display: flex
      flex: 1 1 auto
      order: 5
      align-items: center
      @media screen and (min-width: 1280px)
        display: none

      .slick-slider.slick-initialized
        width: 100%
        display: flex
        background: rgba(123, 134, 140, 0.1)
        border-radius: 50%
        margin: 0 auto

        @media screen and (min-width: 360px)
          width: 340px
          height: 340px

        @media screen and (min-width: 768px)
          width: 450px
          height: 450px

        @media screen and (min-width: 768px) and (orientation: landscape )
          width: 300px
          height: 300px

        @media screen and (min-width: 1000px) and (orientation: portrait )
          width: 650px
          height: 650px

        .slick-prev:before, .slick-next:before
          font-size: 35px
          opacity: 1

          @media screen and (min-width: 768px)
            font-size: 40px
          @media screen and (min-width: 1000px)
            font-size: 50px

        .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before
          opacity: 0.25
        
        .slick-next, .slick-prev
          width: 35px
          height: 35px
          z-index: 9000
          background: #7B868C
          border-radius: 50%

          @media screen and (min-width: 768px)
            width: 40px
            height: 40px
          @media screen and (min-width: 1000px)
            width: 50px
            height: 50px

          &:before
            content: ''
            height: 10px
            width: 10px
            border-right: 2px solid #343841
            border-bottom: 2px solid #343841
            display: block
            margin: 0 auto
            transition: .5s

          &.slick-disabled
            background: rgba(255, 255, 255, 1)

            &:before
              opacity: 0

        .slick-next
          right: -15px

          &:before
            margin-right: 14px
            transform: rotate(-45deg)

            @media screen and (min-width: 768px)
              margin-right: 16px

            @media screen and (min-width: 992px)
              margin-right: 21px
         
        .slick-prev
          left: -15px

          &:before
            margin-right: 10px
            transform: rotate(135deg)

            @media screen and (min-width: 768px)
              margin-right: 12px

            @media screen and (min-width: 992px)
              margin-right: 16px

        .slick-dots
          bottom: 10px !important

        .slick-list
          display: flex
          width: 100%

          .slick-track
            // width: 100% !important
            display: flex
            flex-direction: row
            .slick-slide
              display: flex

              &.slick-active
                z-index: 3

              div
                display: flex
                width: 100%
                .nav-slider__slide
                  display: flex !important
                  flex-direction: column
                  justify-content: center
                  align-items: center
                  width: 100%
                  height: 100%

                  a
                    font-size: 20px
                    line-height: 25px
                    color: #fff
                    text-decoration: none
                    margin: 15px 0

                    @media screen and (min-width: 768px) and (orientation: portrait )
                      font-size: 22px
                      margin: 20px 0

                    @media screen and (min-width: 1280px)
                      font-size: 25px
                      margin: 30px 0

        .slick-dots li button:before
          font-size: 18px
          color: #7B868C
          opacity: 1

        .slick-dots li.slick-active button:before
          color: #fff
