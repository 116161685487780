.ordinaryBtn {
    --duration: .25s;
    transform: translate3d(0, 0, 0);
    transition: all var(--duration) ease;
    position: relative;
    opacity: 1;

    .btnDiv, .btnLink {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        white-space: break-spaces;
        color: inherit;
        text-decoration: none;
        //font-size: 14px;

        &:hover {
            text-decoration: none;
        }
    }

    &:hover {
        @media (hover: hover) {
            cursor: pointer;
            opacity: 0.8;
            box-shadow: 0px 6px 20px 1px var(--shadow-color);
        }
    }

    //colors
    &.black {
        background-color: #323e48;
        --shadow-color: #323e48;
    }

    &.white {
        background: #FFFFFF;
        color: #323e48;
    }

    &.green {
        background-color: #00acd8;
        --shadow-color: #00acd8;
    }

    &.white-grad {
        --shadow-color: white;
    }

}
