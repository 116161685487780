.author-section {
  width: 100%;
  margin-top: 40px;
  overflow: hidden;
  margin-bottom: 50px;

  @media screen and (min-width: 768px) {
    margin-bottom: unset;
    overflow: visible;
  }

  @media screen and (min-width: 1920px) {
    margin-top: 120px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__left {
      width: 100%;
      position: relative;

      @media screen and (min-width: 768px) {
        width: 54%;
      }

      img {
        width: 100%;
        height: auto;
        filter: drop-shadow(40px 40px 40px rgba(0, 0, 0, 0.3));
      }

      .content__btn {
        position: absolute;
        top: 10px;
        right: 5%;
        width: auto;
        height: auto;
        padding: 2px 15px;
        border-radius: 34px;
        background-color: #ffffff;
        font-family: "proxima-nova", sans-serif;
        color: #323e48;
        text-align: center;
        font-weight: bold;
        font-size: 11px;
        line-height: 18px;

        @media screen and (min-width: 768px) {
          font-size: 18px;
          line-height: 130%;
          padding: 4px 20px;
          top: 16px;
        }

        @media screen and (min-width: 1280px) {
          top: 30px;
          font-size: 18px;
          padding: 14px 16px;
          width: 166px;
          height: 47px;
          display: block;
        }
      }
    }

    &__right {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 60px;

      @media screen and (min-width: 768px) {
        width: 38%;
        margin-top: 0;
      }

      @media screen and (min-width: 1280px) {
        padding-top: 20px;
        width: 38%;
      }

      @media screen and (min-width: 1920px) {
        padding-top: 0;
      }

      .author-image {
        text-align: left;

        img {
          width: 100%;
          height: auto;

          @media screen and (min-width: 768px) {
            width: 100px;
          }
          @media screen and (min-width: 1280px) {
            width: 300px;
          }
          @media screen and (min-width: 1920px) {
            width: 400px;
          }
        }
      }

      .author__info {
        display: flex;
        align-items: center;

        @media screen and (min-width: 768px) {
          margin-top: unset;
        }

        @media screen and (min-width: 1280px) {
          margin-top: unset;
        }

        .author__link {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (min-width: 1280px) {
            width: 66.76px;
            height: 58px;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
          }

          img {
            width: 30px;
            height: auto;
          }

          svg {
            position: absolute
          }
        }

        .info__content {
          margin-left: 25px;
          font-weight: 600;
          color: #7b868c;
          font-size: 18px;
          line-height: 24px;

          @media screen and (min-width: 1280px) {
            font-size: 25px;
            line-height: 40px;
          }

          span {
            font-size: inherit;
            color: #323e48;
          }

          .published-data {
            @media screen and (min-width: 768px) and (max-width: 1280px) {
              font-size: 11px;
            }
          }
        }
      }

      .author-description {
        margin-top: 20px;
        max-width: 550px;
        font-size: 18px;
        line-height: 24px;
        color: #323e48;
        font-weight: 600;

        @media screen and (min-width: 1280px) {
          margin-top: 40px;
          font-size: 20px;
          line-height: 28px;
        }
        @media screen and (min-width: 1920px) {
          font-size: 28px;
          line-height: 40px;
          margin-top: 80px;
        }
      }
    }
  }
}
