@keyframes pulse
  0%
    transform: scale(0.85)
  50%
    transform: scale(1)
  100%
    transform: scale(0.85)

@keyframes blur
  from
    backdrop-filter: blur(0px)
  to
    backdrop-filter: blur(20px)

header.header
  padding: 20px 20px
  height: 80px
  display: flex
  justify-content: space-between
  align-items: center
  position: fixed
  width: 100%
  top: 0
  left: 0
  //backdrop-filter: blur(1px)
  z-index: 101
  background: tranparent
  transition: all 0.2s linear

  @media screen and (min-width: 768px)
    height: 90px
    padding: 20px 50px

  @media screen and (min-width: 1280px)
    height: 100px
    padding: 20px 70px

  @media screen and (min-width: 1920px)
    height: 140px
    padding: 35px 90px

  &#no-blur
    backdrop-filter: blur(0)
    background: transparent
    box-shadow: unset
    .contact__info
      .links__text
        color: #fff !important


  &.black:not(#no-blur)
    // background: #FFFFFF
    // box-shadow: 0px 10px 15px rgba(50, 62, 72, 0.25)

    .logo
      background-image: url("../../assets/images/new/Brand assets/Logo.svg")

    .button
      background: #323e48
      color: white

    .hamburger-inner,.hamburger-inner::before,.hamburger-inner::after
      background-color: #323e48

    .contact__info
      .contact__links
        .tel__link
          color: #A2A9AD


  //&.active
  //  background: #FFFFFF !important
  //  box-shadow: 0px 10px 15px rgba(50, 62, 72, 0.25)
  //
  //  .logo
  //    background-image: url("../../assets/images/new/Brand assets/Logo.svg")
  //
  //  .hamburger-inner,.hamburger-inner::before,.hamburger-inner::after
  //    background-color: #323e48
  //
  //  .button
  //    background: #323e48
  //    color: white
  //
  //  .contact__info
  //    .contact__links
  //      color: #A2A9AD
  //      .links__text
  //        color: #A2A9AD !important
  //      .tel__link
  //        color: #A2A9AD !important

  &.active-menu
    background: transparent !important
    box-shadow: unset !important

    .logo
      background-image: url("../../assets/images/new/Brand assets/logo-white.svg") !important

    .hamburger-inner,.hamburger-inner::before,.hamburger-inner::after
      background-color: #fff !important

    .button
      background: #fff !important
      color: #323e48 !important

    .contact__info
      .contact__links
        color: #fff !important
        .links__text
          color: #fff !important
        .tel__link
          color: #A2A9AD
          &--main
            color: #fff !important

  .logo
    width: 120px
    height: 40px
    background-image: url("../../assets/images/new/Brand assets/logo-white.svg")
    background-size: contain
    // background-position: center
    background-repeat: no-repeat

    @media screen and (min-width: 768px)
      width: 150px
      height: 50px

    @media screen and (min-width: 1280px)
      width: 200px
      height: 60px

    @media screen and (min-width: 1920px)
      width: 270px
      height: 70px

  .contact__info
    margin-top: 8px

    @media screen and (max-width: 767px)
      &.active
        display: block

    @media screen and (min-width: 768px)
      display: none
      margin-top: 0

    .contact__links
      display: flex
      color: #fff
      margin: 0 10px
      font-size: 18px
      line-height: 60px

      @media screen and (min-width: 360px)
        margin: 0 20px

      @media screen and (min-width: 992px)
        margin-left: 95px
        margin-top: 30px

      .links__text
        display: none

        @media screen and (min-width: 768px)
          display: block
          color: #A2A9AD
          font-weight: bold

      span
        margin-left: 10px
        display: none

        @media screen and (min-width: 768px)
          display: block

      .tel__link
        color: #A2A9AD
        text-decoration: none
        transition: all 0.2s linear
        display: none
        font-size: 14px

        @media screen and (min-width: 768px)
          display: block
          font-size: 16px
          margin-left: 10px

        &--main
          color: #fff
          display: block
          font-weight: bold
          line-height: 20px
          white-space: nowrap

          @media screen and (max-width: 360px)
            line-height: 20px
            text-align: center

          @media screen and (min-width: 992px )
            color: #A2A9AD

      .separator__span
        color: #A2A9AD

  .controls
    display: flex
    align-items: center
    margin-top: 30px

    @media screen and (min-width: 992px)
      justify-content: flex-end
      min-width: 275px

    @media screen and (max-width: 1279px)
      margin-top: 0

    .engage
      display: none

    &.form

      .engage
        display: block

        button
          min-width: 270px

      .define
        display: none

    button
      margin-right: 20px
      //animation: pulse 6s linear infinite
      min-width: 190px
      padding: 0 15px
      height: 50px
      border-radius: 34px
      outline: none
      border: none
      cursor: pointer
      font-weight: bold
      font-size: 16px
      transition: all 0.2s linear
      background-color: white
      color: #373a3b

      @media screen and (min-width: 1280px)
        height: 60px
        font-size: 18px
        padding: 0 30px

      @media screen and (min-width: 1920px)
        margin-right: 40px


      &:hover
        background-color: #323e48
        color: white

      @media screen and (max-width: 1279px)
        display: none

  .hamburger
    @media screen and (max-width: 1279px)
      padding: 0
      margin-top: 12px

    .hamburger-box
      height: 30px
