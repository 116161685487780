section.media-section {
  position: relative;
  width: 100%;
  margin-top: 35px;

  @media screen and (min-width: 1280px) {
    margin-top: 50px;
  }

  @media screen and (min-width: 1920px) {
    margin-top: 110px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    position: relative;
    //clip-path: inset(0% 0% 0% 0%);

    .media-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media (hover: hover) {
      &:hover {
        .media-content.with-link {
          img {
            transform: scale(1.08);
          }
        }
      }
    }

    &.block-shadow {
      transition: .5s;
      filter: drop-shadow(20px 20px 20px rgba(50, 62, 72, 0.3));
      -webkit-filter:drop-shadow(30px 30px 30px rgba(50, 62, 72, 0.3));
      //-webkit-box-shadow:40px 40px 30px rgba(50, 62, 72, 0.3);
      //box-shadow: 40px 40px 30px rgba(50, 62, 72, 0.3);

      @media screen and (min-width: 1920px) {
        filter: drop-shadow(85px 85px 60px rgba(50, 62, 72, 0.3));
        -webkit-filter:drop-shadow(40px 40px 30px rgba(50, 62, 72, 0.3));
        //-webkit-box-shadow: 85px 85px 60px rgba(50, 62, 72, 0.3);
        //box-shadow: 85px 85px 60px rgba(50, 62, 72, 0.3);
      }
    }

    .media-content {
      width: 100%;
      position: relative;
      //overflow: hidden;
      clip-path: inset(0% 0% 0% 0%);

      &.with-image {
        display: flex;
      }



      img {
        width: 100%;
        height: auto;
        transition: transform 0.5s ease;
      }

      video {
        width: 100%;
        height: auto;
      }

      .video__wrapper {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        padding: 56.25% 0 0 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
  }
}
