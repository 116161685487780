@keyframes opacity
  0%
    opacity: 0.2
  16.16%
    opacity: 1
  33.33%
    opacity: 0.2

@keyframes opacity2
  0%
    opacity: 0.4
    fill: #A24EC1
  16.16%
    opacity: 1
    fill: #A24EC1
  33.33%
    opacity: 0.4
    fill: #A24EC1

.preloader
  background-color: #323e48
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  z-index: 102

  svg
    width: 100px
    height: auto

    .st0, #Group
      opacity: 0.1
      animation: opacity 3s linear infinite

    .st5
      opacity: 0.4
      animation: opacity2 3s linear infinite
      animation-delay: 1s
    .st6,.st7,.st8,.st9,.st10
      animation: opacity 6s linear infinite
      animation-delay: 1s

    .st11,.st12,.st13,.st14
      opacity: 0.1
      animation: opacity 3s linear infinite
      animation-delay: 2s
