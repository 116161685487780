.top-section
  position: relative
  width: 100%
  max-width: 100vw
  height: 100vh
  padding: 150px 0 100px 0
  overflow: hidden

  @media screen and (min-width: 992px)
    padding: 10px 0 200px 0
    overflow: visible

  &:before
    content: ''
    border-left: 160vw solid transparent
    border-right: 160vw solid transparent
    border-top: 85vh solid rgba(255, 255, 255, 0.1)
    position: absolute
    left: -111%
    top: 0
    z-index: -1

    @media screen and (min-width: 768px)
      left: -113%

    @media screen and (min-width: 992px)
      border-left: 50vw solid transparent
      border-right: 50vw solid transparent
      border-top: 120vh solid rgba(255, 255, 255, 0.1)
      // right: -1.5%
      left: unset
      right: -0.5%

    @media screen and (min-width: 1700px)
      border-left: 40vw solid transparent
      border-right: 40vw solid transparent
      border-top: 120vh solid rgba(255, 255, 255, 0.1)
      right: 10%

  .directions
    display: none

    @media screen and (min-width: 992px)
      margin-top: 100px
      color: #a2a9ad
      text-align: center
      word-spacing: 5px
      display: block

  h1
    margin-top: 0
    text-align: center
    font-size: 30px
    color: white

    @media screen and (min-width: 992px)
      font-size: 100px
      margin-top: 100px

    @media screen and (min-width: 1280px)
      margin-top: 150px

  h2.subtitle
    text-align: center
    font-size: 21px
    line-height: 25px
    color: #a2a9ad
    font-weight: 400

    @media screen and (min-width: 992px)
      font-size: 35px
      line-height: 40px

  button
    height: 41px
    padding: 10px 20px
    font-size: 14px
    background-color: transparent
    color: white
    border: 2px solid white
    outline: none
    border-radius: 34px
    margin: 50px auto 0 auto
    display: block
    transition: all 0.3s linear
    font-weight: bold

    @media screen and (min-width: 992px)
      padding: 10px 30px
      height: 68px
      font-size: 18px

    &:hover
      cursor: pointer
      border: 2px solid rgba(255, 255, 255, 0.1)

    img
      margin-left: 5px
      width: 15px
