.columns-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .right-column,
  .left-column {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;

    @media screen and (min-width: 1280px) {
      width: 46%;
    }
    @media screen and (min-width: 1920px) {
      width: 45%;
    }
  }
}
