.subscribe-form {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    margin-top: 60px;
  }

  .form-group {
    max-width: 635px;
    width: 100%;
    margin-right: 30px;

    .form-control {
      width: 100%;
      height: 40px;
      border-radius: 34px;
      background-color: #e8ebed;
      border: none;
      padding: 17px 46px;
      font-family: "proxima-nova", sans-serif;
      color: #323e48;
      outline: none;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      @media screen and (min-width: 768px) {
        height: 50px;
      }

      @media screen and (min-width: 1280px) {
        height: 56px;
        font-size: 22px;
        text-align: left;
      }

      @media screen and (min-width: 1920px) {
        height: 70px;
      }

      &::-webkit-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #323e48;
        opacity: 1; /* Firefox */
      }

      &::-moz-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #323e48;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #323e48;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #323e48;
        opacity: 1; /* Firefox */
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #323e48;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #323e48;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #323e48;
      }
    }
  }

  .submit__btn {
    align-self: center;
    width: 100%;
    height: 40px;
    border-radius: 34px;
    font-family: "proxima-nova", sans-serif;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 16px;

    @media screen and (min-width: 768px) {
      height: 56px;
      width: 94%;
    }

    @media screen and (min-width: 1280px) {
      width: 180px;
      margin-top: unset;
    }
    @media screen and (min-width: 1920px) {
      height: 70px;
      padding: 23px 17px;
    }
  }
}
