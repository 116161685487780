section.our-process
  width: 90%
  margin: 0 auto
  // padding-top: 150px

  @media screen and (min-width: 992px)
    margin: auto
    // padding-top: 200px
    width: 80%
    margin-top: -250px

  @media screen and (min-width: 1530px)
    margin-bottom: 200px
    margin-top: 0

  @media screen and (max-width: 500px)
    width: 100%
    overflow: hidden
