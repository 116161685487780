body
  margin: 0
  padding: 0
  background-color: #323e48
  overflow-x: hidden
  width: 100%
  height: 100%

  *
    box-sizing: border-box
    font-family: "proxima-nova", sans-serif

  section *
    z-index: 2

  h1
    font-family: "museo-slab", serif
    @media screen and (min-width: 1024px)
      letter-spacing: -1px

  h2
    font-family: "proxima-nova", sans-serif
    font-weight: bold

    @media screen and (min-width: 1024px)
      letter-spacing: -1px

  p, li
    font-family: "proxima-nova", sans-serif

  a
    text-decoration: underline

    &:hover
      text-decoration: none

  #CybotCookiebotDialogPoweredByText
    display: none !important

  .CookiebotWidget-main-logo
    display: none !important

  #CybotCookiebotDialog
    #CybotCookiebotDialogPoweredbyCybot
      opacity: 0
      display: none !important
  #CybotCookiebotDialogDetailFooter
    opacity: 0
    display: none

  .CookieDeclaration
    display: none
