section.our-tools
  overflow: hidden

  .content
    width: 90%
    margin: 0 auto
    padding-top: 150px
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    padding-bottom: 80px

    @media screen and (min-width: 992px)
      margin: 0 auto
      padding-top: 200px
      width: 80%

    @media screen and (min-width: 1200px)
      flex-direction: row
      justify-content: space-between

    .info
      text-align: center

      @media screen and (min-width: 1200px)
        width: 47%
        text-align: left

      h2
        font-size: 21px
        line-height: 24px
        color: #323e48

        @media screen and (min-width: 992px)
          font-size: 60px

      p
        color: #323e48
        font-size: 18px
        line-height: 25px
        margin: 0

        @media screen and (min-width: 992px)
          font-size: 22px
          line-height: 35px

      .stack
        margin-top: 30px
        display: flex
        flex-wrap: wrap
        justify-content: center

        @media screen and (min-width: 992px)
          margin-top: 55px
          justify-content: flex-start

        .tool
          width: 130px
          height: 130px
          margin: 5px
          display: flex
          align-items: center
          justify-content: center

          img
            width: 100%

          @media screen and (min-width: 1200px)
            width: 75px
            height: 75px

          @media screen and (min-width: 1300px)
            width: 84px
            height: 75px

          @media screen and (min-width: 1500px)
            width: 95px
            height: 85px

          @media screen and (min-width: 1750px)
            min-width: 17%
            max-width: 18%
            width: 110px
            height: 110px

    .photo
      margin-bottom: 55px
      order: -1

      @media screen and (min-width: 1200px)
        width: 47%
        margin: 10% 0 0
        order: 0

      img
        width: 120%

        @media screen and (min-width: 1200px)
          width: 100%