.page-title {
  .content {
    width: 80%;
    margin: 0 auto;
    padding-top: 110px;

    @media screen and (min-width: 768px) {
      padding-top: 170px;
    }

    @media screen and (min-width: 1280px) {
      padding-top: 250px;
    }

    p.title, p.back__link {
      font-weight: 500;
      letter-spacing: normal;
      color: #9ba2a7;
      margin: 0;
      font-size: 18px;
      line-height: 150%;

      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 130%;
      }

      @media screen and (min-width: 1280px) {
        font-size: 22px;
        line-height: 35px;
      }

      @media screen and (min-width: 1920px) {
        font-size: 28px;
        line-height: 40px;
      }
    }

    p.back__link {
      cursor: pointer;
      text-decoration-line: underline;

      &:hover {
        color: #323e48;
      }
    }

    h1.subtitle {
      font-family: "museo-slab", serif;
      font-weight: bold;
      font-style: normal;
      font-size: 34px;
      line-height: 35px;
      letter-spacing: -0.6px;
      color: #323e48;
      margin: 0;
      margin-top: 8px;

      @media screen and (min-width: 768px) {
        font-size: 34px;
        line-height: 140%;
        letter-spacing: -1.5px;
        margin-top: 17px;
      }

      @media screen and (min-width: 1280px) {
        margin-top: 15px;
        font-size: 64px;
        line-height: 100%;
        letter-spacing: -1.8px;
      }

      //@media screen and (min-width: 1600px) {
      //  margin-top: 15px;
      //  font-size: 65px;
      //  line-height: 80px;
      //  letter-spacing: -1.8px;
      //}

      @media screen and (min-width: 1920px) {
        margin-top: 15px;
        font-size: 90px;
        line-height: 95px;
        letter-spacing: -1.8px;
        max-width: 1385px;
      }
    }

    &.columns {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media screen and (min-width: 1340px){
        flex-direction: row;
      }

      .text {
        max-width: 756px;

        @media screen and (min-width: 2220px) {
          max-width: 1020px;
        }
      }

      .caption {
        color: #323E48;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 44px;

        @media screen and (min-width: 1340px){
          margin-top: 0;
        }

        a {
          color: #323E48;
          font-weight: 700;
        }

        .title {
          font-size: 21px;
          line-height: 115%;
          font-weight: 600;
          font-family: 'museo-slab',  serif;
          margin-bottom: 14px;

          &:not(a) {
            color: #00ACD8;
          }

          @media screen and (min-width: 768px) {
            font-size: 32px;
            margin-bottom: 27px;
            margin-top: 35px;
          }

          @media screen and (min-width: 1280px) {
            font-size: 48px;
          }
        }

        .subtitle {
          padding-bottom: 20px;

          @media screen and (min-width: 768px) {
            padding-bottom: 0;
          }
        }

        .column {
          font-size: 18px;
          font-weight: 900;
          line-height: 156%;

          @media screen and (min-width: 768px) {
            font-size: 24px;
            line-height: 143%;
          }

          @media screen and (min-width: 1280px) {
            font-size: 28px;
          }
        }

        .bottom {
          font-size: 18px;
          color: #323E48;
          max-width: 660px;
          position: relative;
          padding-top: 24px;
          line-height: 156%;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 181px;
            height: 1px;
            background: #9BA2A7;
            display: block;
          }

          *:first-of-type {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 8px;

            @media screen and (min-width: 768px) {
              margin-bottom: 0;
            }
          }

          p {
            margin: 0;
            font-weight: 900;

            &:last-of-type {
              font-weight: 400;
            }
          }

          @media screen and (min-width: 768px) {
            font-size: 20px;
            line-height: 182%;
            padding-top: 35px;
            margin-top: 35px;

            *:first-of-type {
              font-size: 24px;
            }
          }
          @media screen and (min-width: 1280px) {
            font-size: 22px;

            *:first-of-type {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}
