section.service-consultation {
  width: 100%;
  padding: 80px 0px;

  @media screen and (min-width: 1280px) {
    padding: 95px 0px;
  }

  @media screen and (min-width: 1920px) {
    padding: 250px 0px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .image-section {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: 50%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .consultation-content {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 50%;
      }

      @media screen and (min-width: 1280px) {
        padding-top: 75px;
      }

      .consultation__title {
        font-size: 24px;
        line-height: 130%;
        color: #323e48;
        font-family: "museo-slab", serif;

        @media screen and (min-width: 1280px) {
          font-size: 48px;
          line-height: 120%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 65px;
          line-height: 70px;
        }
      }

      .consultation__description {
        margin-top: 30px;
        font-size: 18px;
        line-height: 150%;
        color: #323e48;

        @media screen and (min-width: 1280px) {
          font-size: 20px;
          line-height: 140%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 22px;
          line-height: 40px;
        }
      }

      .consultation__link {
        margin-top: 35px;
        padding: 13px 44px;
        width: 100%;
        border-radius: 34px;
        border: 0;
        color: #fff;
        font-size: 18px;
        line-height: 19px;
        font-weight: bold;
        text-align: center;
        outline: none;
        text-decoration: none;
        display: block;

        @media screen and (min-width: 768px) {
          width: 300px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 18px;
          line-height: 21px;
          width: 454px;
        }

        @media screen and (min-width: 1920px) {
          margin-top: 60px;
          padding: 23px 44px;
        }
      }

      button:hover {
        cursor: pointer;
      }
    }
  }
}
