@keyframes pulse
  0%
    transform: scale(0.85)
  50%
    transform: scale(1)
  100%
    transform: scale(0.85)

@keyframes blur
  from
    backdrop-filter: blur(0px)
  to
    backdrop-filter: blur(20px)

section.menu
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  z-index: -100
  // background-color: #323e48
  transition: all 0.3s linear
  border-radius: 4px
  opacity: 0
  padding: 100px 20px 20px
  background: radial-gradient(32.89% 32.89% at 49.66% 46.47%, #475B6B 0%, #323E48 100%)

  @media screen and (min-width: 768px)
    justify-content: space-around

  @media screen and (min-width: 1280px)
    padding: 0
    justify-content: space-between

  &.active
    border-radius: 0
    opacity: 1
    z-index: 100
    overflow-y: auto

  .directions
    display: none

    @media screen and (min-width: 992px)
      margin-top: 100px
      color: #a2a9ad
      text-align: center
      word-spacing: 5px
      display: block

  .social-icons
    display: flex
    justify-content: center
    margin-top: 40px
    @media screen and (min-width: 992px)
      margin-top: 0

    ul
      margin: 0
      padding: 0
      li
        display: inline-block
        margin-right: 15px

        &:last-of-type
          margin-right: 0

        a
          transition: all 0.3s ease-in-out
          opacity: 0.3

          &:hover
            opacity: 1

          svg
            width: 30px
            height: 30px
            fill: #a2a9ad !important
            @media screen and (min-width: 768px)
              width: 48px
              height: 48px
              &.dark path.st0
                fill: #a2a9ad !important

            .st0
              fill-rule: evenodd
              clip-rule: evenodd
              fill: #a2a9ad !important
              stroke: unset

    img
      width: 25px
      height: 25px
      margin: 0 15px
      opacity: 0.4
      &:hover
        cursor: pointer
        opacity: 1
      @media screen and (min-width: 992px)
        width: 48px
        height: 48px
        margin: 0 5px

  .define
    align-self: flex-end

    @media screen and (min-width: 1280px)
      align-self: unset
      margin-right: unset

    .button
      //animation: pulse 6s linear infinite
      width: 130px
      height: 40px
      border-radius: 34px
      outline: none
      border: none
      cursor: pointer
      font-weight: bold
      font-size: 16px
      transition: all 0.3s linear
      background-color: #ffffff
      color: #373a3b

      @media screen and (min-width: 768px)
        width: 190px
        //height: 50px
        //animation: pulse 6s linear infinite

      @media screen and (min-width: 1280px)
        width: 241px
        height: 68px
        font-size: 18px
        display: none

      &:hover
        background-color: #323e48
        color: white

  .search-section
    width: 100%
    display: flex
    flex-direction: column
    flex: 1 1 auto

    @media screen and (min-width: 1280px)
      margin-top: 150px

    .section__title
      font-weight: 600
      font-size: 18px
      line-height: 25px
      color: #fff
      text-align: center
      order: 2
      display: none

      max-width: 850px
      margin: 0 auto
      margin-top: 10px

      @media screen and (min-width: 400px)
        margin-top: 20px

      @media screen and (min-width: 768px)
        margin-top: 30px
        font-size: 25px
        line-height: 40px

        br
          display: none

      @media screen and (min-width: 1280px)
        margin-top: 130px
        display: block
        font-size: 48px
        line-height: 60px
        order: unset
        br
          display: block
      span
        color: #A2A9AD

    .search__field
      position: relative
      margin: -40px 0 0 0
      width: 10%
      order: 1
      transition: all 0.3s linear

      &.active
        width: 100%

      @media screen and (min-width:  1280px) and (orientation: landscape )
        margin: 50px auto 0
        width: 660px

      //@media screen and (min-width: 768px) and (orientation: landscape )
      //  margin: 10px auto 0

      @media screen and (min-width: 1280px)
        order: unset
        margin: 50px auto 0
        width: 660px

      @media screen and (min-width: 1920px)
        margin: 70px auto 0

      .search-input
        padding: 0 65px
        width: 100%
        height: 40px
        background: #7D868B
        border-radius: 20px
        font-size: 13px
        line-height: 25px
        text-align: center
        color: #323E48
        outline: none
        border: none
        -moz-appearance: none
        appearence: none
        -webkit-appearance: none

        &::placeholder
          color: #a2a9ad


        &.active
          padding: 0 40px
          background: #fff

        @media screen and (min-width: 1280px)
          font-size: 16px
          padding: 0 30px
          height: 55px
          font-size: 22px
          line-height: 40px
          text-align: left
          background: #E6E7ED
          border-radius: 34px

        @media screen and (min-width: 1280px)
          height: 60px

        @media screen and (min-width: 1920px)
          height: 70px

      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration,
      input[type="search"]::-webkit-search-cancel-button:after,
      input[type="search"]::-webkit-textfield-decoration-container
        display: none
        -webkit-appearance: none

      .submitButton
        padding: 0
        border: none
        outline: none
        background: transparent
        position: absolute
        top: 7px
        left: 50px
        width: 25px
        height: 25px
        background-image: url(../../../assets/images/new/search-icon-white.svg)
        background-repeat: no-repeat
        background-size: cover
        transition: .5s

        @media screen and (min-width: 1280px)
          left: 15px

        &:hover
          cursor: pointer

        &.active
          background-image: url(../../../assets/images/new/search-icon.svg)
          background-repeat: no-repeat
          background-size: cover
          padding: unset
          left: 15px

        @media screen and (min-width: 1280px)
          left: unset
          right: 20px
          width: 40px
          height: 40px
          top: 7px
          background-image: url(../../../assets/images/new/search-icon.svg)

        @media screen and (min-width: 1280px)
          top: 10px

        @media screen and (min-width: 1920px)
          width: 50px
          height: 50px

      .close-icon
        display: none
        position: absolute
        top: 10px
        right: 20px
        width: 20px
        height: 20px
        background-image: url(../../../assets/images/new/close-search-icon.svg)
        background-repeat: no-repeat
        background-size: cover

        &.active
          display: block

        @media screen and (min-width: 768px)
          display: none

    h1.tel.mobile
      display: flex
      justify-content: center
      order: 4
      margin: 10px 0 0 0

      @media screen and (min-width: 400px)
        margin: 20px 0

      @media screen and (min-width: 768px)
        margin: 20px 0 50px 0

      @media screen and (min-width: 1280px)
        display: none

      .tel__link
        font-size: 30px
        line-height: 35px
        text-align: center
        letter-spacing: -0.6px
        color: #FFFFFF
        text-decoration: none

  .navigation
    display: flex
    justify-content: space-between
    flex-direction: column
    margin-bottom: 15px

    @media screen and (min-width: 768px)
      margin-bottom: 40px
    @media screen and (min-width: 1280px)
      flex-direction: row
      width: 80%
      margin-bottom: 80px

    @media screen and (min-width: 1920px)
      margin-bottom: 160px

    .right
      @media screen and (min-width: 1280px)
        margin-right: -5%
    .left
      @media screen and (min-width: 1280px)
        margin-left: -5%

    .center
      display: flex
      flex-direction: column
      flex: 1 1 auto
      @media screen and (min-width: 1280px)
        //margin-left: -5%
        justify-content: center

      .social-icons
        display: none
        @media screen and (min-width: 1280px)
          display: flex

      .stars
        display: none
        @media screen and (min-width: 1280px)
          display: flex
          justify-content: center

        img
          margin: 0 4px
          width: 25px
          height: 25px

          @media screen and (min-width: 992px)
            width: 39px
            height: 39px

      .products-in-menu
        display: flex
        justify-content: center
        flex: 1 1 auto

        @media screen and (min-width: 1280px)
          margin: 55px 0
          flex: 0 1 auto

        .products
          display: flex
          justify-content: center
          align-items: center
          flex-direction: column

          @media screen and (min-width: 1280px)
            flex-direction: row

          .product__link
            font-family: "museo-slab", serif
            margin: 5px 20px
            padding: 10px 5px
            border: 2px solid #7B868C
            border-radius: 34px
            font-size: 16px
            line-height: 19px
            text-align: center
            color: #fff
            background: transparent
            letter-spacing: -0.5px
            text-decoration: none
            width: 85%
            transition: all 0.5s ease
            min-width: 100%

            @media screen and (min-width: 360px)
              margin: 5px 30px
              padding: 10px 25px

            @media screen and (min-width: 768px)
              margin: 10px 30px
              padding: 15px 25px
              font-size: 21px
              line-height: 25px

            @media screen and (min-width: 1280px)
              margin: 0 30px
              height: 100%
              display: flex
              align-items: center
              justify-content: center
              min-width: 400px

            @media screen and (min-width: 1450px)
              min-width: 420px

            @media screen and (min-width: 1920px)
              padding: 20px 30px

            @media (hover: hover)
              &:hover
                border: 2px solid white

    .left, .right
      display: none

      @media screen and (min-width: 1280px)
        display: flex
        flex-direction: column
        align-items: center
        width: 263px
        border-left: 1px solid #979797
        border-right: none
        padding: 0 0 0 40px
        align-items: flex-start

      a
        font-size: 18px
        color: #a2a9ad
        text-decoration: none
        margin: 10px 0

        @media screen and (min-width: 992px)
          margin-right: 0
          font-size: 22px

          &:nth-child(2)
            margin: 14px 0

        @media screen and (min-width: 1920px)
          line-height: 40px
          margin: 13px 0

        &:hover
          cursor: pointer
          color: white

    .right
      @media screen and (min-width: 1280px)
        border-left: none
        border-right: 1px solid #979797
        align-items: flex-end
        padding: 0 40px 0 0


      .define_link
        display: none

        @media screen and (min-width: 992px)
          display: flex

    .left
      border-top: 1px solid #979797
      padding-top: 30px

      @media screen and (min-width: 992px)
        border-top: none
        padding-top: 0

    h2.title
      font-size: 30px
      line-height: 35px
      color: #a2a9ad
      font-weight: 400
      max-width: 600px
      text-align: center
      order: 1

      b
        font-weight: 600
        color: white

      @media screen and (min-width: 992px)
        font-size: 48px
        line-height: 60px
        width: 60%
