section.available-services
  margin: 0 auto 0 auto

  @media screen and (max-width: 992px)
    width: 100%

  @media screen and (min-width: 992px)
    width: 85%
    margin: 25vh auto 0 auto

  @media screen and (min-width: 1700px)
    width: 1600px
    margin: 20vh auto 0 auto

  .wrapper
    display: flex
    flex-direction: column
    position: relative
    z-index: 3

    &:nth-of-type(1) .group .number span
      color: #93d500

    &:nth-of-type(2) .group .number span
      color: #993dbb

    &:nth-of-type(3) .group .number span
      color: #00acd8

    &:nth-of-type(4) .group .number span
      color: #D8D8D8

    @media screen and (min-width: 992px)
      margin-bottom: 35vh

    @media screen and (min-width: 1200px)
      margin-bottom: 30vh

    .group
      margin-bottom: 20vh
      display: flex
      flex-direction: column

      @media screen and (min-width: 992px)
        margin-bottom: 0

      .number
        display: flex
        align-items: center

        @media screen and (max-width: 992px)
          width: 100%
          max-width: 90%
          margin: 0 auto

        @media screen and (min-width: 1800px)
          width: 106%

        div
          display: flex
          align-items: center

        .controls
          color: white
          cursor: pointer
          display: none
          font-size: 25px
          opacity: .5

          margin: 0 auto
          transform: translateX(-70%)

          &:hover, &:active
            opacity: 1

          @media screen and (min-width: 1200px)
            display: flex

          img
            margin-left: 20px

        span
          color: white
          font-size: 33px
          margin-right: 20px
          font-family: "proxima-nova", sans-serif
          font-weight: bold

          @media screen and (min-width: 992px)
            font-size: 56px

        img
          height: 40px

          @media screen and (min-width: 992px)
            height: auto

      h2.type
        margin-top: 0
        color: white
        font-size: 30px
        position: relative
        font-family: "museo-slab"

        @media screen and (max-width: 992px)
          width: 100%
          max-width: 90%
          margin: 0 auto
          margin-bottom: 25px

        @media screen and (min-width: 992px)
          font-size: 48px
          margin-bottom: 50px

      .select
        display: flex
        position: relative
        justify-content: space-between
        overflow-x: scroll
        user-select: none

        @media screen and (max-width: 992px)
          margin-left: 20px

        &::-webkit-scrollbar
          width: 4px
          height: 4px

        &::-webkit-scrollbar-corner
          background: transparent
        &::-webkit-scrollbar-button
          width: 2px
          height: 2px
        &::-webkit-scrollbar-track
          border: 0 none rgba(255, 255, 255, 0.1)
          border-radius: 4px
          background: rgba(255, 255, 255, 0.1)

          &:hover
            background: rgba(255, 255, 255, 0.1)
          &:active
            background: rgba(255, 255, 255, 0.1)

        &::-webkit-scrollbar-thumb
          border: 0 none blue
          border-radius: 4px
          background: rgba(255, 255, 255, 0.8)

          &:hover
            background: rgba(255, 255, 255, 1)
          &:active
            background: rgba(255, 255, 255, 1)



        @media screen and (min-width: 1800px)
          overflow-x: visible

        .option
          min-width: 150px
          height: 175px
          border: 4px solid #7B868C
          color: #7B868C
          margin-right: 40px
          display: flex
          flex-direction: column
          justify-content: center

          @media screen and (max-width: 992px)
            margin-right: 20px

          @media screen and (min-width: 992px)
            min-width: 250px
            max-width: 250px
            height: 295px

          &.active
            .tick
              visibility: visible

          .tick
            visibility: hidden
            width: 100%
            height: 50%
            display: flex
            align-items: center
            justify-content: center

            svg
              width: 40px
              height: 40px

              @media screen and (min-width: 992px)
                width: 75px
                height: 75px

          .text
            height: 50%
            display: flex
            align-items: center
            padding: 0 15px

            @media screen and (min-width: 992px)
              padding: 0 30px


            h2
              color: white
              margin-bottom: 0
              text-align: left
              font-size: 16px
              line-height: 20px

              @media screen and (min-width: 992px)
                font-size: 28px
                line-height: 32px

        .price
          color: white
          position: relative

          svg
            width: 205px
            height: 175px

            @media screen and (min-width: 992px)
              width: auto
              height: auto

          .text
            position: absolute
            width: 100%
            height: 100%
            text-align: center
            display: flex
            flex-direction: column
            padding: 17px 0

            @media screen and (min-width: 992px)
              padding: 34px 0

            h2.title
              margin: 0
              font-size: 12px
              font-weight: normal

              @media screen and (min-width: 992px)
                font-size: 22px
                line-height: 1.14

            .amount
              flex-grow: 1
              display: flex
              flex-direction: column
              justify-content: center

              h2
                margin: 0

                span
                  line-height: 1
                  font-size: 36px
                  position: relative
                  display: inline-block

                  @media screen and (min-width: 992px)
                    font-size: 60px
                    line-height: 1.17

                  &::after
                    content: '*'
                    position: absolute
                    top: 2px
                    right: -15px
                    font-size: 20px

                    @media screen and (min-width: 992px)
                      font-size: 30px
                      right: -20px
                      top: 7px

              span
                color: white
                font-size: 12px

                @media screen and (min-width: 992px)
                  font-size: 22px
                  line-height: 1.14

            .tip
              color: white
              font-size: 10px

              @media screen and (min-width: 992px)
                font-size: 12px
                line-height: 1.33

    &:nth-of-type(1)
      .option
        .tick
          background-color: #93d500

        &:hover, &.active
          cursor: pointer
          border: 4px solid #93d500

      .price
        svg
          g#Define-your-Project-Main
            fill: #93d500
            stroke: #93d500

      .number
        .controls
          display: none

    &:nth-of-type(2)
      .option
        .tick
          background-color: #993dbb

        &:hover, &.active
          cursor: pointer
          border: 4px solid #993dbb

      .price
        svg
          g#Define-your-Project-Main
            fill: #993dbb
            stroke: #993dbb

    &:nth-of-type(3)
      .option
        .tick
          background-color: #00acd8

        &:hover, &.active
          cursor: pointer
          border: 4px solid #00acd8

      .price
        svg
          g#Define-your-Project-Main
            fill: #00acd8
            stroke: #00acd8

    &:nth-of-type(4)
      .option
        .tick
          background-color: #fff

          svg
            path
              fill: #000 !important

        &:hover, &.active
          cursor: pointer
          border: 4px solid #fff

      .price
        svg
          g#Define-your-Project-Main
            fill: #D8D8D8
            stroke: #D8D8D8
    .controls-wrapper
      display: flex
      justify-content: center

      @media screen and (min-width: 1800px)
          width: 106%


      .controls-bottom
        font-size: 25px
        display: none
        cursor: pointer
        margin-top: 60px
        align-items: center
        justify-content: flex-end
        text-align: right
        color: white
        opacity: .5

        &:hover, &:active
          opacity: 1

        @media screen and (min-width: 992px)
          margin-bottom: 35vh
          display: flex
          visibility: hidden

        @media screen and (min-width: 1200px)
          visibility: visible !important
          display: flex
          margin-bottom: 30vh

        // @media screen and (min-width: 1700px)
        //   margin-right: 90px

        img
          transform: rotate(180deg)
          margin-left: 15px
