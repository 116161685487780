@keyframes pulse
  0%
    transform: scale(0.85)
  50%
    transform: scale(1)
  100%
    transform: scale(0.85)

section.hero
  position: relative
  background-size: cover
  background-repeat: no-repeat
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  background-attachment: fixed
  background-position: center
  overflow: hidden
  z-index: 0

  &.safari
    background-attachment: scroll

  @media not all and (min-resolution: .001dpcm)
    @supports (-webkit-appearance: none)
      background-attachment: scroll

  @media screen and (max-width: 550px)
    .description-title
      max-width: 200px

  .mouse
    display: flex
    justify-content: center
    position: absolute
    bottom: 24%
    z-index: 2

    img
      cursor: pointer
      animation: pulse 3s linear infinite

  .first-title
    margin-top: 0

  .btn
      border-radius: 34px
      padding: 14px 30px
      cursor: pointer
      outline: none
      font-size: 18px
      border: 0
      font-weight: 600
      -webkit-tap-highlight-color: transparent

      @media screen and (min-width: 992px)
        padding: 23px 30px


  h1
    color: white
    font-size: 50px
    text-align: center

    @media screen and (min-width: 992px)
      font-size: 100px
      max-width: 80%

    span
      color: #a2a9ad

  h2
    color: #a2a9ad
    font-size: 25px
    text-align: center

    strong
      color: white
      font-weight: bold

    @media screen and (min-width: 550px)
      font-size: 35px

    @media screen and (min-width: 992px)
      font-size: 48px
      line-height: 60px

  video
    position: absolute
    object-fit: cover
    width: 100%
    height: 100%
