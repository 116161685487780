.subscribe-us {
  overflow: hidden;
  width: 100%;
  margin-top: 80px;
  padding-bottom: 90px;
  box-shadow: 80px 80px 60px rgba(123, 134, 140, 0.135899);

  @media screen and (min-width: 1280px) {
    padding: 120px 0 50px;
  }

  @media screen and (min-width: 1920px) {
    padding: 170px 0 210px 0;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .content__form {
      width: 100%;
      align-self: center;

      @media screen and (min-width: 768px) {
        width: 55%;
      }

      .form__title {
        max-width: 820px;
        font-family: "museo-slab", serif;
        font-weight: bold;
        color: #323e48;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.3px;
        width: 80%;
        margin: 0 auto;

        &.thank-you {
          margin-bottom: 200px;

          @media screen and (max-width: 1280px) {
            margin-bottom: 80px;
          }
          &::first-line {
            color: #93D500;
          }
        }

        @media screen and (min-width: 768px) {
          font-size: 34px;
          line-height: 130%;
          text-align: left;
          letter-spacing: unset;
          width: 100%;
          margin: unset;
        }

        @media screen and (min-width: 1280px) {
          font-size: 48px;
          line-height: 120%;
        }

        @media screen and (min-width: 1920px) {
          font-size: 65px;
          line-height: 70px;
        }
      }

      .form__description {
        margin-top: 25px;
        font-family: "proxima-nova", sans-serif;
        font-weight: 600;
        color: #323e48;
        font-size: 18px;
        line-height: 150%;

        @media screen and (min-width: 1280px) {
          margin-top: 30px;
          line-height: 25px;
        }

        @media screen and (min-width: 1280px) {
          margin-top: 50px;
          font-size: 20px;
          line-height: 140%;
        }
        @media screen and (min-width: 1920px) {
          font-size: 28px;
          line-height: 40px;
        }
      }

      .form__text {
        margin: 25px auto 0px;
        font-weight: 600;
        color: #9ba2a7;
        text-align: center;
        font-size: 11px;
        line-height: 18px;
        width: 90%;

        br {
          display: none;
        }

        @media screen and (min-width: 768px) {
          font-size: 16px;
          line-height: 150%;
          color: #323e48;
          width: 100%;
          margin: 37px 0 0;
        }

        @media screen and (min-width: 1280px) {
          margin-top: 45px;
          font-size: 20px;
          line-height: 140%;
          text-align: left;

          br {
            display: block;
          }
        }

        @media screen and (min-width: 1920px) {
          font-size: 22px;
          line-height: 40px;
        }

        .text__link {
          color: #9ba2a7;

          @media screen and (min-width: 768px) {
            color: #323e48;
          }
        }
      }

      .social-icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;

        @media screen and (min-width: 768px) {
          flex-direction: row;
          margin-top: unset;
        }

        span {
          font-size: 21px;
          line-height: 24px;
          font-family: "museo-slab", serif;

          @media screen and (min-width: 768px) {
            font-size: 24px;
            line-height: 130%;
            margin-right: 10px;
          }

          @media screen and (min-width: 1280px) {
            font-size: 32px;
            line-height: 40px;
          }
          @media screen and (min-width: 1920px) {
            font-size: 45px;
            line-height: 55px;
          }
        }

        ul {
          display: flex;
          padding-inline-start: 0px;

          @media screen and (min-width: 768px) {
            padding-inline-start: 20px;
          }

          @media screen and (min-width: 1280px) {
            padding-inline-start: 40px;
          }

          li {
            display: inline-block;
            margin-right: 25px;

            @media screen and (min-width: 768px) {
              margin-right: 5px;
            }

            @media screen and (min-width: 1280px) {
              margin-right: 15px;
            }
            &:last-of-type {
              margin-right: 0;
            }

            a {
              transition: all 0.3s ease-in-out;

              @media screen and (min-width: 768px) {
                opacity: 0.5;
              }

              &:hover {
                opacity: 1;
              }

              svg {
                width: 25px;
                height: 25px;

                @media screen and (min-width: 1280px) {
                  width: 48px;
                  height: 48px;
                }

                &.dark path.st0 {
                  fill: #9ba2a7 !important;

                  @media screen and (min-width: 768px) {
                    fill: #323e48 !important;
                  }
                }
              }
            }
          }
        }

        @media (max-width: 680px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          top: 18px;
          margin-bottom: 45px;
        }
      }
    }

    .content__image {
      width: 100%;
      align-self: center;

      @media screen and (min-width: 768px) {
        width: 38%;
        margin-left: unset;
      }

      @media screen and (min-width: 1280px) {
        width: 30%;
        align-self: unset;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
