.defineWrap
  padding-top: 0 !important

.defineBtn
  width: 100% !important

  .btn-submit
    padding-top: 0
    width: 100% !important

.defineParagraph
  color: #7B868C !important

.define-project
  overflow: hidden
