@keyframes fadeIn
  from
    opacity: 0

  to
    opacity: 1

section.case-study
  width: 100vw
  max-width: 100%
  height: 100vh
  overflow: hidden

  .slick-list, .slick-initialized
    overflow: hidden

  .slick-list
    .slick-track
      .slick-slide
        .case-study-slide
          h3.subTitle
            font-size: 21px
            line-height: 1.14
            color: white
            margin: 0 auto

            @media screen and (min-width: 992px)
              margin: 0
              margin-bottom: 20px
              font-size: 48px
              line-height: 55px
              font-family: 'museo-slab'
              max-width: 1250px
              font-weight: lighter

          .case-study-description
            margin-bottom: 120px

            @media screen and (min-height: 600px) and (min-width: 768px)
              margin-bottom: 40px

            @media screen and (min-height: 800px) and (min-width: 768px)
              margin-bottom: 80px

            @media screen and (min-height: 1100px) and (min-width: 768px)
              margin-bottom: 180px

        &:first-child
          .case-study-slide
            h3.subTitle
              font-size: 16px
              color: white
              font-weight: lighter
              margin: 0 auto

              @media screen and (min-width: 992px)
                margin-bottom: 20px
                max-width: unset
                font-size: 25px
                line-height: unset
                font-family: inherit

  .slick-next
    right: unset
    z-index: 999
    left: 0
    transform: rotate(180deg)

    @media screen and (max-height: 700px)
      bottom: 28.5% !important

    &::after
      content: 'View project'
      display: block
      font-size: 14px
      color: white
      width: 135px
      position: absolute
      transform: rotate(180deg)
      bottom: 55px
      right: -50px

    &:hover::after
      color: white

    @media screen and (min-width: 992px)
      transform: rotate(180deg)
      left: 100px !important
      animation: fadeIn 3s linear

      &::after
        transform: rotate(-90deg)
        font-size: 22px
        width: 150px
        bottom: -90px
        color: #a2a9ad

  .slick-prev
    z-index: 999
    transform: none

    @media screen and (max-height: 700px)
      bottom: 28.5% !important

    &.hide
      &::after
        content: ''

    &::after
      content: 'Close'
      width: 135px
      display: block
      font-size: 14px
      color: white
      position: absolute
      bottom: -15px
      right: -47px

      @media screen and (min-width: 992px)
        content: 'Close project'
        bottom: 15px
        transform: rotate(-90deg)
        color: #a2a9ad

    &:hover::after
      color: white

    @media screen and (min-width: 992px)
      transform: unset
      left: 100px !important
      animation: fadeIn 3s linear

      &::after
        width: 150px
        font-size: 22px
        bottom: 135px
        right: -50px

  .slick-next, .slick-prev
    outline: none
    opacity: 1
    top: 20%
    left: 47%
    height: 40px
    width: 40px
    transition: all 1s linear

    @media screen and (max-width: 376px)
      top: auto
      bottom: 30%

    @media screen and (max-width: 992px)
      top: auto
      bottom: 25%

    @media screen and (min-width: 992px)
      width: 52px
      height: 52px
      bottom: 11.2%
      top: unset

    &:before
      content: ''
      width: 40px
      height: 40px
      background-image: url("../../assets/images/new/solution-open.svg")
      display: block
      background-size: cover
      font-size: unset
      opacity: 1

      @media screen and (min-width: 992px)
        width: 52px
        height: 52px

  .slick-disabled
    display: none !important

  .case-study-slide
    position: relative
    height: 100vh
    width: 100%
    outline: none

    .slide-image
      width: 100%
      height: 100%
      background-position: center
      background-size: cover
      background-repeat: no-repeat

    video
      position: absolute
      object-fit: cover
      top: 0
      width: 100%
      height: 100%

    .content
      position: absolute
      left: 10%
      bottom: 5%
      max-width: 1400px
      text-align: center
      width: 80%

      @media screen and (min-width: 568px)
        bottom: 10%

      @media screen and (min-width: 992px)
        left: 175px
        margin: auto
        width: auto
        padding-left: 50px
        text-align: left
        border-left: 2px solid rgba(162, 169, 173, 0.59)

      h2.title
        margin-top: -10px
        color: white
        font-size: 21px
        font-family: "museo-slab", serif

        @media screen and (min-width: 992px)
          font-size: 65px
          line-height: 70px
          margin-bottom: 34px

      ul
        padding-left: 25px
        list-style: none

        @media screen and (max-width: 991px)
          padding: 0

        li
          font-size: 16px
          line-height: 1.56
          color: white
          margin-bottom: 5px
          position: relative

          @media screen and (min-width: 992px)
            font-size: 22px
            line-height: 35px
            padding-left: 5px
            margin-bottom: 10px

            &::after
              content: ''
              width: 20px
              height: 20px
              position: absolute
              top: 7px
              left: -25px
              background-image: url('../../assets/images/new/o small.svg')
              background-size: contain

              @media screen and (min-width: 992px) and (max-height: 800px)
                top: 5px
                width: 10px
                height: 10px

          @media screen and (min-width: 992px) and (max-height: 800px)
            font-size: 15px !important
            line-height: 130%

      p
        font-size: 11px
        line-height: 18px
        color: white
        margin-bottom: 10px
        display: none

        &:last-of-type
          font-size: 8pt
          line-height: 1.1

        @media screen and (min-width: 992px)
          margin-bottom: auto
          font-size: 18px
          line-height: 35px
          display: block

        small
          font-size: 8pt

        a
          text-decoration: none
          color: #fff

          &:hover
            cursor: pointer
            color: #0000ff

      .tabs
        z-index: 2
        position: relative

        @media screen and (max-width: 991px)
          display: flex
          justify-content: center
          flex-wrap: wrap

        button
          width: 122px
          outline: none
          color: white
          background: #7b868c
          border-radius: 17px
          border: 1px solid #7b868c
          padding: 5px 0
          margin: 20px 20px 0 0
          cursor: pointer
          transition: all 0.3s linear
          font-size: 16px
          font-weight: bold

          @media screen and (max-width: 991px)
            margin: 10px 10px 0px

          .lines
            vertical-align: bottom
            margin-right: 15px
            display: none

            @media screen and (min-width: 992px)
              display: inline-block

            .long, .short
              margin-top: 3px
              height: 3px
              border-radius: 10px
              background-color: white
              transition: all 0.3s linear

            .long
              width: 30px
            .short
              width: 15px

          @media screen and (min-width: 992px)
            font-size: 18px
            height: 68px
            width: 240px
            border-radius: 34px
            border: 2px solid white
            background-color: transparent

          @media screen and (min-width: 992px) and (max-height: 850px)
            height: 45px

          &:hover, &.active
            color: black
            background: white
            .lines
              .long, .short
                background-color: #323e48