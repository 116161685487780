section.hero-triangle
  background-color: #323e48
  min-height: 100vh

  .content
    height: 100vh
    padding: 50px 0 120px 0
    width: 90%
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: center

    @media screen and (min-width: 992px)
      margin: 0 auto
      width: 80%
      min-height: 920px

    @media screen and (min-width: 1530px)
      padding: 100px 0 100px 0
      flex-direction: row
      flex-wrap: wrap
      justify-content: space-between

    .info
      max-width: 720px
      margin: 0 auto
      text-align: center
      color: white

      @media screen and (min-width: 992px)
        margin: auto

      @media screen and (min-width: 1530px)
        margin-left: 0
        text-align: left

      h1
        font-size: 21px
        line-height: 24px
        margin-bottom: 20px

        @media screen and (min-width: 992px)
          font-size: 65px
          line-height: 70px
          margin-bottom: 58px

        span
          color: #7b868c

      h2
        font-weight: 400
        font-size: 16px
        line-height: 25px
        color: #a2a9ad
        letter-spacing: initial

        @media screen and (min-width: 992px)
          font-size: 35px
          line-height: 50px

        b
          color: white

    .meet-team
      display: flex
      justify-content: center

      @media screen and (min-width: 1530px)
        display: block
        width: 100%

      a
        color: #a2a9ad
        text-decoration: none
        font-size: 18px
        display: flex
        align-items: center
        font-weight: 500
        position: relative
        padding-left: 46px

        &:before
          content: ''
          width: 30px
          height: 30px
          background-image: url("../../../assets/images/new/solution-open.svg")
          transform: rotate(180deg)
          display: block
          background-size: cover
          font-size: unset
          opacity: 0.75
          position: absolute
          top: -7px
          left: 0

          @media screen and (min-width: 992px)
            width: 52px
            height: 52px
            top: -13px

        &:hover
          color: white

        &:hover:before
          opacity: 1

        @media screen and (min-width: 992px)
          font-size: 28px
          padding-left: 68px

    .figures
      position: fixed
      width: 300px
      max-width: 466px
      margin: 70px auto 0 auto
      order: 1
      z-index: 1

      @media screen and (min-width: 1530px)
        position: fixed
        opacity: 0 !important
        top: 50%
        right: 10%
        align-self: center
        text-align: center
        display: flex
        justify-content: center

      &.in
        opacity: 1 !important
        position: fixed
        top: 5%
        right: 10%

        img.triangle
          opacity: 1

        @media screen and (max-width: 1529px)
          top: 50%
          left: 50%
          right: unset
          -webkit-transform: translate(-50%, -20%)
          transform: translate(-50%, -20%)

      @media screen and (min-width: 992px)
        width: 57%

      @media screen and (min-width: 1530px)
        order: 0
        margin: 200px auto
        display: flex

        &::before
          content: url('../../../assets/images/x.svg')
          position: absolute
          width: 390px
          top: -110%
          left: 7%
        &::after
          content: url('../../../assets/images/new/Image-Mask-1.svg')
          position: absolute
          width: 390px
          bottom: -90%
          left: 10%

      img.triangle
        width: 100%
        margin-left: 15%
        opacity: 0
        transition: opacity 0.5s linear

        @media screen and (min-width: 1530px)
          margin-left: 24%

      img.hexagon-top
        transform: rotate(90deg)
        position: absolute
        top: -30px
        width: 50px
        right: 38%

        @media screen and (min-width: 992px)
          width: 75px
          top: -40px

        @media screen and (min-width: 1530px)
          width: auto
          top: -50px
          right: unset

      img.hexagon-right
        transform: rotate(90deg)
        position: absolute
        right: 1%
        bottom: 50px
        width: 50px

        @media screen and (min-width: 992px)
          width: 75px
          bottom: 75px

        @media screen and (min-width: 1530px)
          width: auto
          right: 0
          bottom: 60px

      img.hexagon-left
        transform: rotate(90deg)
        position: absolute
        bottom: 50px
        left: 7%
        width: 50px

        @media screen and (min-width: 992px)
          width: 75px
          bottom: 75px

        @media screen and (min-width: 1530px)
          width: auto
          bottom: 60px
          left: 0
